import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { Redirect  } from "react-router-dom";
import * as apiCaller from '../../../../helpers/apiCaller';
import serviceUrls from '../../../../constants/serviceUrls';
import Pagination from '../../../../components/tools/Pagination';
import moment from 'moment';
import { convertMeterCommissionStatus, convertMeterSiteAuditFormStatus } from '../../../../helpers/converters';
import { AsteriskRed } from '../../../../components/tools/Others';
import { InputText}  from '../../../../components/tools/BootstrapControls'
import { isPositiveWholeNumber } from '../../../../helpers/validators';

const formStatusOptions = [
    {id:'PENDING_MY_ACTION',display:'Pending for My Action'},
    {id:'PENDING_APPROVAL',display:'Pending QA Review'},
    {id:'COMPLETED',display:'Completed'},
    {id:'ALL',display:'All Forms'},
];

const formTypeOptions = [
    {id:'ALL',display:'All'},
    {id:'ALL_NON_URBAN',display:'All Non-urban Metering'},
    {id:'ALL_FLOODPLAIN',display:'All Floodplain Harvesting'}
];

const formCreatedPeriodOptions = [
    {id:'PAST_30',display:'In past 30 days'},
    {id:'PAST_60',display:'In past 60 days'},
    {id:'PAST_90',display:'In past 90 days'},
    {id:'PAST_YEAR',display:'In past one year'},
    {id:'ALL',display:'All forms'},
]


export default class HomeSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formIdStr:'',
            formIdError:null,

            formId:null,
            keyword : '',
            formStatus : null,
            formType: formTypeOptions[1],
            createdPeriod:formCreatedPeriodOptions[4],

            searching:false,

            dataForDisplay:null,
            redirectTo: null
        };
    }

    componentDidMount(){this.setState({formStatus:formStatusOptions[3]},this.search)}
    onFormStatusChange =(o) =>{this.setState({formStatus : o},this.search);}
    onFormTypeChange =(o) =>{this.setState({formType : o},this.search);}
    onCreatedPeriodChange =(o) =>{this.setState({createdPeriod : o},this.search);}

    onFormIdChange = (e) => {
        if(e.target.value.trim() == '' || isPositiveWholeNumber(e.target.value)) {
            this.setState({formIdStr:e.target.value, formIdError:null, formId:parseInt(e.target.value)})
        } else {
            this.setState({formIdStr:e.target.value, formIdError:'Not a valid ID', formId:null})
        }
    }

    onFormIdBlur =() => {
        if(this.state.formId!=null){
            this.search();
        }
    }
    onKeywordChange = (e) =>{
        this.setState({keyword:e.target.value})
    }

    data = null;
    paginationDataSetter = null;
    paginationDataProvider = (dataSetter) => {
        this.paginationDataSetter = dataSetter;
    }

    refreshDisplayRecord = (data) => {
        this.setState({ dataForDisplay: data });
     }

    search = async ()=>{
        this.setState({searching:true})
        await apiCaller.post(serviceUrls.others.homeSearch,{
            formId:this.state.formId,
            keyword:this.state.keyword, 
            formStatus:this.state.formStatus?this.state.formStatus.id:null,
            formType:this.state.formType?this.state.formType.id:null,
            //createdPeriod:this.state.createdPeriod?this.state.createdPeriod.id:null
        }).then((data)=>{
            this.data = data.result;
            if (this.paginationDataSetter) this.paginationDataSetter(this.data );
        }).finally(()=>{
            this.setState({searching:false})
        });
    }

    convertFormSource = (source, subSource,formStatus) => {
        switch(source) {
            case 'MeterCommission': return "Meter Compliance Process"
            case 'MeterSiteAudit': return "Meter Site Audit Form"
            case 'MeterRegistration': return formStatus=="SUBMITTED"?<><strong><AsteriskRed/></strong> Meter Registration</>:"Meter Registration"
            case 'MeteringEquipmentInformation':
                switch(subSource) {
                    case 'VALIDATION_CERT': return "Validation Certificatie"
                    case 'DESIGN_CERT': return "Design Certificate"
                    case 'ACCURACY_CERT': return "Accuracy Certificate"
                    case 'TRANSITIONAL_ARRANGEMENTS_CERT': return "Transitional Arrangements Certificate"
                    case 'MAINTENANCE_VALIDATION_CERT': return "Maintenance Validation Certificate"
                    default : return null;
                }
            case 'BenchmarkDetails': return "Survey Benchmarks Validation Certificate"
            case 'RatingTableCertificate': return "Update Rating Table Certificate"
            case 'StorageManagementValidation': return "Storage Management Validation Certificate"
            case 'SecondaryEquipmentValidation': return "Secondary Validation Certificate"
            default : return null;
        }
    }

    convertFormType = (formType) => {
        switch(formType) {
            case 'NON_URBAN': return <span class="badge bg-primary">NUMR</span>
            case 'FPH': return <span class="badge bg-success">FPH</span>
            default : return null;
        }
    }

    convertFormStatus = (form) =>{
        switch(form.source) {
            case 'MeterCommission': 
                return <>Status: <b>{convertMeterCommissionStatus(form.formStatus)}</b></>;
            case 'MeterSiteAudit': 
                return <>Status: <b>{convertMeterSiteAuditFormStatus(form.formStatus)}</b></>;
            case 'MeterRegistration': 
                switch(form.formStatus) {
                    case 'DRAFT': return <>Status: <b>Draft</b></>
                    case 'SUBMITTED': return <>Status: <b>Submitted</b></>
                    case 'INSTALLED': return <>Status: <b>Installed</b></>
                    case 'REMOVED': return <>Status: <b>Removed</b></>
                    case 'PENDING_APPROVAL': return <>Status: <b>Pending Approval</b></>
                    default : return <>Status: <b>Unknown</b></>
                }
            case 'MeteringEquipmentInformation': 
            case 'BenchmarkDetails': 
            case 'RatingTableCertificate': 
            case 'StorageManagementValidation': 
            case 'SecondaryEquipmentValidation': 
                switch(form.formStatus) {
                    case 'DRAFT': return <>Status: <b>Draft</b></>
                    case 'SUBMITTED': return <>Status: <b>Submitted</b></>
                    default : return <>Status: <b>Unknown</b></>
                }
            default : return null;
        }
    }

    renderOneForm = (form) => {

        return <li class="list-group-item">
            <div className='row'>
                <div className='col-md-2 mb-3'>
                    {this.convertFormType(form.formType)}
                    <br/><span class="fs-80 text-secondary">Created: <b>{moment(form.createdDate).format('DD/MM/YYYY')}</b></span>
                </div>
                <div className='col-md-4 mb-3'>
                    {this.convertFormSource(form.source, form.subSource, form.formStatus)}
                    {form.source=='MeterRegistration' && form.formType=='FPH' && form.measurementMethod=='STORAGE_MESUREMENT' && <i className='fs-80 ms-2'>(Storage)</i>}
                    {form.source=='MeterRegistration' && form.formType=='FPH' && form.measurementMethod=='POINT_OF_INTAKE' && <i className='fs-80 ms-2'>(Point of Intake)</i>}
                    {form.source=='MeteringEquipmentInformation' && form.formType=='FPH'  && <i className='fs-80 ms-2'>(Point of Intake)</i>}
                    <br/><span class="fs-80 text-secondary">ID: #<b>{form.id}</b></span>
                    {form.installationType && <span className='fs-80 text-primary'><br/>{form.installationType}</span>}
                </div>
                <div className='col-md-4 mb-3'>
                    <span class="fs-80 text-secondary">{this.convertFormStatus(form)}</span>
                    
                    {form.approval && <><br/><span class="fs-80 text-secondary">Approval: <b className='text-primary'>{form.approval}</b></span></>}

                    {form.authorisedOrgName && <><br/><span class="fs-80 text-secondary">Approval Holder: <b>{form.authorisedOrgName}</b></span></>}
                    {form.authorisedHolderFistName && <><br/><span class="fs-80 text-secondary">Approval Holder: <b>{form.authorisedHolderFistName} {form.authorisedHolderLastName}</b></span></>}

                    {form.esid && <><br/><span class="fs-80 text-secondary">ESID: <b>{form.esid}</b></span></>}
                    {form.meterNumber && <><br/><span class="fs-80 text-secondary">Meter No.: <b>{form.meterNumber}</b></span></>}
                    {form.reference && <><br/><span class="fs-80 text-secondary">Reference: <b>{form.reference}</b></span></>}
                </div>
                <div className='col-md-2 text-end'>
                    <button className='btn btn-primary' disabled={this.state.searching} onClick={()=>this.openForm(form)}>Open Form</button>
                </div>
            </div>

        </li>
    }
    
    openForm =(form) => {
        if(form.source == 'MeterCommission') {
            switch(form.formStatus){
                case 'PENDING_SITE_REGO':
                case 'PENDING_REGO_APPROVAL':
                case 'DELETED':
                    this.setState({redirectTo:`/compliance-process/${form.id}/site-registration`});return;
                case 'PENDING_LID_REGISTRATION':
                    this.setState({redirectTo:`/compliance-process/${form.id}/lid-registration`});return;
                default:
                    this.setState({redirectTo:`/compliance-process/${form.id}/validation`});return;
            }
        }else if(form.source == 'MeterSiteAudit') {
            this.setState({redirectTo:`/site-audit/${form.id}/form`});return;
        } else if(form.source == 'MeterRegistration') {

            switch(form.formType){
                case 'NON_URBAN':
                    window.location.href = `/NonUrbanRegistration?id=${form.id}`;
                    return;
                default:
                    window.location.href = `/FPHRegistration?id=${form.id}`;
                    return;
            }
        } else if(form.source == 'MeteringEquipmentInformation') {
            switch(form.subSource){
                case 'VALIDATION_CERT':
                    if(form.formType=='NON_URBAN') window.location.href = `/validation-certificate-num?id=${form.id}`;
                    else window.location.href = `/validation-certificate-point-of-intake?id=${form.id}`;
                    return;
                case 'DESIGN_CERT':
                    window.location.href = `/MeterDesignCertificate?id=${form.id}`;
                    return;
                case 'ACCURACY_CERT':
                    if(form.formType=='NON_URBAN') window.location.href = `/accuracy-certificate-num?id=${form.id}`;
                    else window.location.href = `/accuracy-certificate-fph?id=${form.id}`;
                    return;
                case 'TRANSITIONAL_ARRANGEMENTS_CERT':
                    window.location.href = `/TransitionalReporting?id=${form.id}`;
                    return;
                case 'MAINTENANCE_VALIDATION_CERT':
                    window.location.href = `/validation-certificate-num?id=${form.id}`;
                    return;
            }
        } else if(form.source == 'BenchmarkDetails') {
            window.location.href = `/SurveyBenchmarkCertificate?id=${form.id}`;
            return;
        } else if(form.source == 'RatingTableCertificate') {
            window.location.href = `/UpdateRatingTable?id=${form.id}`;
            return;
        } else if(form.source == 'StorageManagementValidation') {
            window.location.href = `/StorageManagementValidation?id=${form.id}`;
            return;
        } else if(form.source == 'SecondaryEquipmentValidation') {
            window.location.href = `/SecondaryEquipmentValidation?id=${form.id}`;
            return;
        }
    }

    render() {
        return <>
            {this.state.redirectTo && <Redirect to={this.state.redirectTo}  />}
            <div className='card mb-5'>
                <div class="card-header bg-primary text-white">
                    <b>Search My Forms {this.state.redirectTo}</b>
                </div>
                <div className='card-body'>
                    
                    <div className='row'>
                        <div className='col-md-3 mb-3'>
                            <InputText 
                                label="Form ID"
                                value={this.state.formIdStr}
                                error={this.state.formIdError}
                                disabled={this.state.searching}
                                onChange={this.onFormIdChange}
                                onBlur={this.onFormIdBlur}
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label className='form-label'>Progress</label>
                            <Select
                                isDisabled={this.state.searching}
                                getOptionLabel={o=>o.display}
                                getOptionValue={o=>o}
                                options={formStatusOptions}
                                onChange={this.onFormStatusChange}
                                value={this.state.formStatus}
                            />
                        </div>
                        <div className='col-md-3 mb-3' >
                            <label className='form-label'>Form Type</label>
                            <Select
                                isDisabled={this.state.searching}
                                getOptionLabel={o=>o.display}
                                getOptionValue={o=>o}
                                options={formTypeOptions}
                                value={this.state.formType}
                                onChange={this.onFormTypeChange}
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label className='form-label'>Created</label>
                            <Select
                                isDisabled={this.state.searching}
                                getOptionLabel={o=>o.display}
                                getOptionValue={o=>o}
                                options={formCreatedPeriodOptions}
                                value={this.state.createdPeriod}
                                onChange={this.onCreatedPeriodChange}
                            />
                        </div>
                    </div>
                    <div>
                        <label className='form-label'>Keyword <i className='fs-80'>(Approval / ESID / Worksite ID / ROI)</i></label>
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <input type="text" class="form-control" disabled={this.state.searching} onChange={this.onKeywordChange} value={this.state.keyword} />
                            </div>
                            <div className='col-md-6 mb-3'>
                                <button class="btn btn-primary" disabled={this.state.searching} type="button" onClick={this.search}>Search</button>        
                            </div>
                        </div>
                    </div>

                    <hr/>

                    {this.state.dataForDisplay && this.state.dataForDisplay.length == 0 && <div className='text-center p-3 bg-light'>No record matching criteria</div>}

                    {this.state.dataForDisplay && this.state.dataForDisplay.length > 0 && <>
                        <ul className={`list-group ${this.state.searching ? 'opacity-25':''}`}>
                            {this.state.dataForDisplay.map(d=>this.renderOneForm(d))}
                        </ul>
                    </>}

                    <AsteriskRed/><i className='fs-80'>The form is on temporary hold due to administrative reasons. Please contact WaterNSW via "Submit an Enquiry" on top right section of this page for more information.</i>
                    
                </div>
                <div class="card-footer text-center">
                    <div class="row justify-content-md-center">
                        <div class="col"></div>
                        <div class="col-md-auto">
                            <Pagination numberOfRecordsPerPage={50} dataProvider={this.paginationDataProvider} displayRecordsUpdate={this.refreshDisplayRecord} />
                        </div>
                        <div class="col"></div>
                    </div>
                </div>
            </div>
        </>
    }
}