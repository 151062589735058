import { FlatForm, emailValidator, latitudeNswValidator, longitudeNswValidator, mandatoryValidator, noDigitValidator, positiveNumberValidator, positiveWholeNumberValidator, preConditionToValidate, trueValidator, txtMandatoryValidator } from "../../../helpers/FormHelper";

export const getValidationForm = (formName, context, triggerCommissioningAction, saveAction,goToStep2Action, goToStep3Action, goToStep4Action, goToStep5Action, submitAction) => {

    const changeAndSaveValidators = {
        customersPumpNumber:[positiveWholeNumberValidator('Please enter a valid number')],
        meterSize : [positiveWholeNumberValidator('Please enter a valid number')],
        latitude:[latitudeNswValidator],
        longitude:[longitudeNswValidator],
        pipeLengthUpstream:[positiveNumberValidator('Please enter a valid number')],
        pipeLengthDownstream:[positiveNumberValidator('Please enter a valid number')],
        meterReading:[positiveNumberValidator('Please enter a valid number')],
        meterReadingAtLidInstallation:[positiveNumberValidator('Please enter a valid number')],
        replacedMeterFinalReading:[positiveNumberValidator('Please enter a valid number')],
        scalingFactor:[positiveNumberValidator('Please enter a valid number')],
        certNoneHolderEmail:[emailValidator('Please enter a valid email')],
        dasAccessNoneHolderEmail:[emailValidator('Please enter a valid email')],
        dasCurrentReading:[positiveNumberValidator('Please enter a valid number')],
        installerDQPCMI: [positiveWholeNumberValidator('Please enter a valid CMI number')],
        installerDQPName:[noDigitValidator('Please enter a valid name')]
    }
    const result = new FlatForm(formName, context);
    result.initialise([
        "id",
        "extractionSite",
        "works",
        "meteringSystemTypes",
        "meterTypeModels",
        "meterTypeModel",
        "dlwcProperty",
        "customersSiteName",
        "customersPumpNumber",
        "longitude",
        "latitude",
        "meterSerialNo",
        "meterSize",
        "dlwcLot",
        "meterUnitOfMeasures",
        "patternApprovalNumber",

        "pipeLengthUpstream",
        "pipeLengthDownstream",
        "supplyType",
        "lid",
        "meterFlowUnits",
        "dataCollectorSerialNo",
        "lIDType",
        "lIDTelemetry",
        "telemetryType",
        "scalingFactor",
        "newMeterEquipment",
        "meterReading",
        "meterReadingDate",
        "formStatus",
        "complying",
        "nonComplianceReasons",
        "documents",
        "tamperSealNumbers",
        "isFirstMeterForLID",
        "parentMeter",
        "allMetersForLid",

        "generatedCertificatePath",
        "pdfGenerated",
        "pdfGeneratedDate",
        "dqpNotes",

        "certificateSendToNoneHolder",
        "certNoneHolderEmail",
    
        "dasAccessToNoneHolder",
        "dasAccessNoneHolderEmail",

        "meterReadingAtLidInstallation",
        "lidInstallationDate",
        "replacedMeterFinalReading",
        "formWorkStatus",

        "unitInDasCorrect",
        "dasCurrentReading",
        "maintenanceNotificateDate",

        "installerDQPName",
        "installerDQPCMI"
    ])
    .setValueChangedValidators(changeAndSaveValidators)
    .setPostChangeActions({
        lIDType:(val, vals,form)=>{
            form.vals({scalingFactor:null}, false);
            form.errs({scalingFactor:null})
        },
        complying:(val,vals,form)=>{
            form.vals({nonComplianceReasons:[]});
        },
        certificateSendToNoneHolder:(val,vals,form)=>{form.vals({certNoneHolderEmail:null});form.errs({certNoneHolderEmail:null})},
        dasAccessToNoneHolder:(val,vals,form)=>{form.vals({dasAccessNoneHolderEmail:null});form.errs({dasAccessNoneHolderEmail:null})},
        formWorkStatus:(val,vals,form)=>{
            form.runAction("save");
        },
        unitInDasCorrect:(val,vals,form)=>{form.vals({dasCurrentReading:''}, false);form.errs({dasCurrentReading:null})}
    })
    .defineAction('triggerCommissioning',triggerCommissioningAction,{
        meterUnitOfMeasures:[mandatoryValidator('This is required')],
        meterFlowUnits : [mandatoryValidator('This is required'),(val,vals,form)=>{
            if(vals.parentMeter && vals.parentMeter.meterFlowUnits.id != val.id){
                return "This must be changed to " + vals.parentMeter.meterFlowUnits.name + " to be consistent with the first meter" ;
            }
            return null;
        }],
    })
    .defineAction('save',saveAction,changeAndSaveValidators)
    .defineAction('goToStep2',goToStep2Action,{
        meterUnitOfMeasures:[mandatoryValidator('This is required')],
        meterFlowUnits : [mandatoryValidator('This is required'),(val,vals,form)=>{
            if(vals.parentMeter && vals.parentMeter.meterFlowUnits.id != val.id){
                return "This must be changed to " + vals.parentMeter.meterFlowUnits.name + " to be consistent with the first meter" ;
            }
            return null;
        }],
    })
    .defineAction('goToStep3',goToStep3Action,{
        //dlwcProperty : [mandatoryValidator('This is required')],
        //dlwcLot : [mandatoryValidator('This is required')],
        //customersSiteName : [txtMandatoryValidator('This is required')],
        customersPumpNumber:[positiveWholeNumberValidator('Please enter a valid number')],
        meterSerialNo : [txtMandatoryValidator('This is required')],
        meterSize : [txtMandatoryValidator('This is required'),positiveWholeNumberValidator('Please enter a valid number')],
        dataCollectorSerialNo:[preConditionToValidate((val, vals)=>vals.parentMeter==null,txtMandatoryValidator('This is required'))],
        pipeLengthUpstream:[positiveNumberValidator('Please enter a valid number')],
        pipeLengthDownstream:[positiveNumberValidator('Please enter a valid number')],
        lIDType : [mandatoryValidator('This is required')],
        scalingFactor:[preConditionToValidate((val, vals)=>vals.lIDType && vals.lIDType.key=='PULSE',txtMandatoryValidator("This is required")),positiveNumberValidator('Please enter a valid number')],
        tamperSealNumbers:[(val)=>{
            if(val.length==0) return "Please provide at least one tamper seal number";
            return null;
        }]
    })
    .defineAction('goToStep4',goToStep4Action,{
        complying: [mandatoryValidator('This is required')],
        nonComplianceReasons:[preConditionToValidate((val, vals)=>vals.complying===false,(val,vals)=>{
            if(val.length==0) return "Please provide at least one valid reason";
            return null;
        })],
    })
    .defineAction('goToStep5',goToStep5Action,{
        /*
        meterReadingDate:[preConditionToValidate((val, vals)=>{
            return vals.newMeterEquipment == '3' || vals.newMeterEquipment == '1' 
        },mandatoryValidator('This is required'))],
        meterReading:[preConditionToValidate((val, vals)=>{
            return vals.newMeterEquipment == '3' || vals.newMeterEquipment == '1' 
        },txtMandatoryValidator('This is required')),positiveNumberValidator('Please enter a valid number')],
        */
        dqpNotes:[txtMandatoryValidator('This is required')],

        lidInstallationDate:[mandatoryValidator('This is required')],
        meterReadingAtLidInstallation:[txtMandatoryValidator('This is required'),positiveNumberValidator('Please enter a valid number')],
        replacedMeterFinalReading:[preConditionToValidate((val, vals)=>vals.newMeterEquipment == '3',txtMandatoryValidator('This is required')),positiveNumberValidator('Please enter a valid number')],
        certNoneHolderEmail:[preConditionToValidate((val, vals)=>vals.certificateSendToNoneHolder===true,txtMandatoryValidator('This is required')),emailValidator('Please enter a valid email')],
        dasAccessNoneHolderEmail:[preConditionToValidate((val, vals)=>vals.dasAccessToNoneHolder===true,txtMandatoryValidator('This is required')),emailValidator('Please enter a valid email')],

        //unitInDasCorrect:[mandatoryValidator('This is required'),trueValidator("Please verify flow units in DAS and confirm as Yes to proceed further.")],
        unitInDasCorrect:[trueValidator("Please verify flow units in DAS and confirm as Yes to proceed further.")],
        dasCurrentReading:[preConditionToValidate((val, vals)=>vals.unitInDasCorrect===true,txtMandatoryValidator('This is required')),positiveNumberValidator('Please enter a valid number')],

        maintenanceNotificateDate:[preConditionToValidate((val, vals)=>vals.newMeterEquipment == '5',mandatoryValidator('This is required')),(val,vals)=>{
            if(val && vals.lidInstallationDate) {
                if((new Date(vals.lidInstallationDate).getTime() - new Date(val).getTime()) < 0 ) {
                    return "This date must be before the Site Commission Date"
                }
            }
        }],
        installerDQPCMI: [positiveWholeNumberValidator('Please enter a valid CMI number')],
        installerDQPName:[noDigitValidator('Please enter a valid name')]
    })
    .defineAction('submit',submitAction)
    ;
    return result;
}