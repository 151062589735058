import React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { SetCurrentMenu , menuItems} from '../layout/base/BasePageLayout';
import Home from './others/components/Home';
import CommisioningProcessRoutes from './meterCommisioningProcess/Routes'
import AdminRoutes from './admin/Routes'
import DasWorkspaceAccessRoutes from './dasWorkspaceAccess/Routes'
import MeterSiteAuditRoutes from './meterSiteAudit/Routes'



class Routes  extends React.Component {

    render(){        
        return  <Router>
            <Switch>
                <Route exact path={["/","/home"]}>
                    <SetCurrentMenu menuConnector={this.props.menuConnector} currentMenu={menuItems.home()}>
                      <Home/>
                    </SetCurrentMenu>
                </Route>
                <Route path="/compliance-process">
                    <SetCurrentMenu menuConnector={this.props.menuConnector} currentMenu={menuItems.nonUrbanMetering()}>
                        <CommisioningProcessRoutes/>
                    </SetCurrentMenu>
                </Route>
                <Route path="/das-access">
                    <SetCurrentMenu menuConnector={this.props.menuConnector} currentMenu={menuItems.nonUrbanMetering()}>
                        <DasWorkspaceAccessRoutes/>
                    </SetCurrentMenu>
                </Route>
                <Route path="/admin">
                    <SetCurrentMenu menuConnector={this.props.menuConnector} currentMenu={menuItems.admin}>
                        <AdminRoutes/>
                    </SetCurrentMenu>
                </Route>
                <Route path="/site-audit">
                    <SetCurrentMenu menuConnector={this.props.menuConnector} currentMenu={menuItems.nonUrbanMetering()}>
                        <MeterSiteAuditRoutes/>
                    </SetCurrentMenu>
                </Route>
            </Switch>
        </Router>
    }
}

export default Routes;