import React from 'react';
import { withRouter } from "react-router";
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import { AsteriskRed, LoadingPage } from '../../../components/tools/Others';
import { getLidInstallationForVendorForm } from '../forms/LidInstallationForVendorForm';
import {  converters } from '../../../helpers/converters';
import FileUploader from '../../../components/common/FileUploader';
import * as bootstrap from 'bootstrap';

const initState = {
    initialised:false,
    invalidLink:false,
    confirmingAcceptance:false,
    sendingAcceptance:false,
    acceptanceSucceed:false,
    confirmingRejection:false,
    sendingRejection:false,
    rejectionSucceed:false,
    confirmingSubmission:false,
    sendingSubmission:false,
    submissionSucceed:false
}


class LidInstallationVendor  extends React.Component {

    guid;
    lidConfirmationModal;
    fileUploaderDisplayListUpdate;
    installationTypeAcknowledgementModal;
    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
        this.guid = props.match.params.guid;
        this.lidInstallationForm = getLidInstallationForVendorForm('lidIntallationForm',this, this.acceptForm, this.confirmRejection, this.submitForm, this.openLidConfirmationModal);
    }

    componentDidMount() {
        this.lidConfirmationModal = new bootstrap.Modal(document.getElementById('lid-confimation-modal'));
        this.refreshPage();
    }

    openLidConfirmationModal =() => {
        this.lidConfirmationModal.show();
    }

    acceptForm = async()=>{
        this.setState({confirmingAcceptance:true},this.lidInstallationForm.disableForm)
    }

    submitForm = async()=>{
        this.setState({
            confirmingSubmission:true,
            sendingAcceptance:false,
            acceptanceSucceed:false,
            confirmingRejection:false,
        },this.lidInstallationForm.disableForm)
    }

    cancelAcceptance = async() => {
        this.setState({confirmingAcceptance:false},this.lidInstallationForm.enableForm)
    }

    rejectForm= async()=>{
        this.setState({confirmingRejection:true},this.lidInstallationForm.disableForm)
    }

    cancelSubmission = async() => {
        this.setState({confirmingSubmission:false},this.lidInstallationForm.enableForm)
    }

    cancelRejection = async() => {
        const form = this.lidInstallationForm;
        form.enableField("telemetryType",false)
        form.enableField("lid",false)
        form.enableField("isFirstMeterForLID",false)
        form.enableField("existingLid",false)
        form.enableField("meterType",false)
        form.enableField("meterScalingFactor",false);
        form.errs({
            comments:null
        },false);
        this.setState({confirmingRejection:false})
    }

    confirmAcceptance = async () => {
        this.lidInstallationForm.disableForm(()=>this.setState({sendingAcceptance:true}));
        uiHelpers.showProgressOnTop('Please wait...');
        
        await apiCaller.post(serviceUrls.meterCommission.acceptLidRegistrationForVendor,{lidInstallationForm:this.lidInstallationForm.vals()})
        .then((data)=>this.wholePageDataBind(data,()=>{
            this.setState({acceptanceSucceed:true})
        })).finally(this.wholePageAfterRefresh);
        
    }

    

    confirmRejection = async () => {
        this.lidInstallationForm.disableForm(()=>this.setState({sendingRejection:true}));
        uiHelpers.showProgressOnTop('Sending back to DQP...');
        
        await apiCaller.post(serviceUrls.meterCommission.rejectLidRegistrationToDQP,{lidInstallationForm:this.lidInstallationForm.vals()})
        .then((data)=>this.wholePageDataBind(data,()=>{
            this.setState({rejectionSucceed:true}, this.lidInstallationForm.disableForm)
        })).finally(this.wholePageAfterRefresh);
        
    }

    confirmSubmission = async () => {
        this.lidInstallationForm.disableForm(()=>this.setState({sendingSubmission:true}));
        uiHelpers.showProgressOnTop('Please wait...');
        
        await apiCaller.post(serviceUrls.meterCommission.submitLidRegistrationForVendor,{lidInstallationForm:this.lidInstallationForm.vals()})
        .then((data)=>this.wholePageDataBind(data,()=>{
            this.setState({submissionSucceed:true}, this.lidInstallationForm.disableForm)
        })).finally(this.wholePageAfterRefresh);
        
    }

    refreshPage = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.lidInstallationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.getMeterCommissionLidRegistrationForVendor,{guid:this.guid}, false)
        .then(this.wholePageDataBind).finally(this.wholePageAfterRefresh);
    }

    wholePageDataBind = (data, callback=null) =>{

        const newState = JSON.parse(JSON.stringify(initState));

        newState.initialised = true;

        if(data.pendingFormExist) {
            this.setState(newState,()=>{
                this.lidInstallationForm.vals(data.lidInstallationForm.fields, false);
                this.lidInstallationForm.setOptions(data.lidInstallationForm.optionsForFields, false);
                if(this.lidInstallationForm.val("lidInstallationStatus" ) == "ACCEPTED"){
                    this.lidInstallationForm.disableField("telemetryType",false)
                    this.lidInstallationForm.disableField("lid",false)
                    this.lidInstallationForm.disableField("isFirstMeterForLID",false)
                    this.lidInstallationForm.disableField("existingLid",false)
                    this.lidInstallationForm.disableField("meterType",false)
                    this.lidInstallationForm.disableField("meterScalingFactor",false)
                    this.lidInstallationForm.disableField("comments",false)
                }
                this.lidInstallationForm.enableForm(callback);
            })
        } else {
            newState.invalidLink = true;
            this.setState(newState);
        }
        
        
    }

    wholePageAfterRefresh = () => {
        uiHelpers.hideProgressOnTop();
        window.scrollTo(0, 0);
    }

    renderInvalidLink() {
        return <div className='alert alert-danger mb-0'>
            <b>You are accessing an invalid link. There are multiple possible reason for this:</b>
            <ul className='my-3'>
                <li>You do not have the correct link.</li>
                <li>Your original link has expired / used.</li>
            </ul>
        </div>
    }

    getExistingLidLable = (o)=>
        <div className="text-truncate">This LID is connecting to {o.meters.map((m,i)=>{
            if(i==0) return <><strong className='text-primary fs-80'>{m.meterSiteId}</strong></>;
            return <>, <strong className='text-primary fs-80'>{m.meterSiteId}</strong></>;
        })}
    </div>;

    //---------------------------------
    fileUploaded = async(files)=>{
        const form = this.lidInstallationForm;
        let document = null;
        files.forEach(f=>{
            document = {
                documentId:f.id,
                fileName:f.fileName,
                fileType: f.fileType,
                fileUrl: f.fileUrl,
                fileSize: f.fileSize,
                fileOriginalName: f.fileOriginalName,
                fileGUID: f.fileGUID,
                description: f.description
            };
        });
        form.vals({certificate:document}, true,()=>{
            this.fileUploaderDisplayListUpdate([form.val('certificate')])
        });
    }

    onfileDeleted=(file)=>{
        const form = this.lidInstallationForm;
        form.vals({certificate:null}, true, ()=>{
            this.fileUploaderDisplayListUpdate([])
        });
     }

     //---------------------------------

     onReturnClick = () => {
        const form = this.lidInstallationForm;
        form.vals({
            telemetryType:null,
            lid:null,
            isFirstMeterForLID:null,
            existingLid:null,
            meterType:null,
            meterScalingFactor:''
        },false);
        form.errs({
            telemetryType:null,
            lid:null,
            isFirstMeterForLID:null,
            existingLid:null,
            meterType:null,
            meterScalingFactor:null
        },false);
        form.disableField("telemetryType",false)
        form.disableField("lid",false)
        form.disableField("isFirstMeterForLID",false)
        form.disableField("existingLid",false)
        form.disableField("meterType",false)
        form.disableField("meterScalingFactor",false)
        this.setState({confirmingRejection:true});
     }

    renderMainContent() {
        if(!this.state.initialised) return <LoadingPage />;
        if(this.state.invalidLink) return this.renderInvalidLink();

        const form = this.lidInstallationForm;
        return <>
            <div className='alert alert-primary'>Please complete this form by providing the correct LID information and test it for the below meter</div>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Information About the Meter</b>
                </div>
                <div className='card-body'>
                    {form.renderUneditableTextInputRow('installationType',<>Installation Type</>,{rowClasses:'mb-3', converter:(o)=>o?o.name:null})}
                    {form.val("workspaceId") && form.renderUneditableTextInputRow('workspaceId',<>Workspace ID</>,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow('workApproval',<>Approval Number</>,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow('esid',<>ESID</>,{rowClasses:'mb-3'})}
                    {form.val("installationType") && <>
                        {(form.val("installationType").key=="N_M_N_L" || form.val("installationType").key=="R_M_N_L") && <>
                            {form.renderUneditableTextInputRow('meterNumber',<>Meter Number</>,{rowClasses:'mb-3'})}
                            {form.renderUneditableTextInputRow('manufacturerAndModel',<>Manufacturer and Model</>,{rowClasses:'mb-3', converter:(o)=>o?o.name:null})}
                            {form.val('manufacturerAndModel') && form.val('manufacturerAndModel').key=='Other' && form.renderUneditableTextInputRow('otherManufacturerAndModelText',<>Other Manufacturer and Model</>,{rowClasses:'mb-3'})}
                        </>}
                        {form.val("installationType").key=="E_M_N_L" && <>
                            {form.renderUneditableTextInputRow('existingWasMeter',<>Meter Number</>,{rowClasses:'mb-3', converter:o=>o.meterNumber})}
                            {form.renderUneditableTextInputRow('manufacturerAndModel',<>Manufacturer and Model</>,{rowClasses:'mb-3', converter:(o)=>o?o.name:null})}
                            {form.val('manufacturerAndModel') && form.val('manufacturerAndModel').key=='Other' && form.renderUneditableTextInputRow('otherManufacturerAndModelText',<>Other Manufacturer and Model</>,{rowClasses:'mb-3'})}
                        </>}
                        {form.val("installationType").key=="E_M_R_L" && <>
                            {form.renderUneditableTextInputRow('existingMeter',<>Meter Number</>,{rowClasses:'mb-3', converter:o=>o.meterNumber})}
                            {form.renderUneditableTextInputRow('existingMeter',<>Workspace ID</>,{rowClasses:'mb-3', converter:o=>o.meterSiteId})}
                            {form.renderUneditableTextInputRow('existingMeter',<>Manufacturer and Model</>,{rowClasses:'mb-3', converter:o=>o.meterModelName})}
                        </>}
                    </>}
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>New LID Details</b>
                </div>
                <div className='card-body'>
                    {form.renderUneditableTextInputRow('isLidTelemetry',<>Is the LID being used for Telemetry?</>,{rowClasses:'mb-3', converter:converters.common.converBoolToYesNo})}
                    {
                        form.val('isLidTelemetry')===true 
                        && 
                        form.renderDropDownRow('telemetryType',<>LID Telemetry Type <span className='text-danger'>*</span></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})
                    }
                    {
                        (form.val('isLidTelemetry')===false || (form.val('isLidTelemetry')===true && form.val('telemetryType'))) 
                        && 
                        form.renderDropDownRow('lid',<>LID Device <span className='text-danger'>*</span></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})
                    }
                    {form.val('lid') && (form.val('lid').allowMultiple ?form.renderUneditableTextInputRow("meterType",<>LID Type <AsteriskRed/></>,{converter:o=>o && o.name,rowClasses:'mb-3'}):form.renderDropDownRow("meterType",<>LID Type <AsteriskRed/></>,(o)=>o.id,o=>o.name,{rowClasses:'mb-3'}))}
                    {form.val('meterType') && form.val('meterType').key=='PULSE' && form.renderTextBoxRow('meterScalingFactor',<>Scaling Factor (Multiplier) <AsteriskRed/></>,{rowClasses:'mb-3'})}
                    {   
                        form.val('lid') && form.val('lid').allowMultiple 
                        && 
                        form.renderYesNoOptionsRow('isFirstMeterForLID',<>Is this the first meter registered to this LID? <span className='text-danger'>*</span></>,{rowClasses:'mb-3'})
                    }
                    {form.val('isFirstMeterForLID') ===false && <>
                        {form.renderDropDownRow('existingLid',<>Existing LID <span className='text-danger'>*</span></>,(o)=>o.id,this.getExistingLidLable,{rowClasses:'mb-3'})}
                        {form.renderUneditableTextInputRow('existingLid',"LID Serial Number",{converter:value=>value?value.lidSerialNumber:null})}
                    </>}
                    
                    {this.state.confirmingRejection ? <div className='card border-primary border-2 p-2'>
                        {form.renderTextBoxRow('comments',<>Comments <AsteriskRed/> <br/><i className='text-primary fs-80'>(This is required when you are returning this form to DQP)</i></>,{})}
                    </div>:form.renderTextBoxRow('comments',<>Comments</>,{rowClasses:'mb-3'})}
                    
                    {form.val("lidInstallationStatus" ) == "ACCEPTED" && <>
                        <hr/>
                        {form.renderYesNoOptionsRow('tested',<>LID to DAS Test Successful? <span className='text-danger'>*</span></>,{rowClasses:'mb-3'})}
                        <div className='row'>
                            <div className='col-md-4 py-2'>
                            LID Registration Certificate <AsteriskRed/>
                            </div>
                            <div className='col-md-8'>
                                <FileUploader
                                    allowFileTypes={["application/pdf"]}
                                    fileTypeErrorMsg="Only PDF supported"
                                    singleFileMode={true}
                                    maxFileSizeMB={10}
                                    exceedFileSizeErrorMsg = "Your file is too big"
                                    disabled={form.isFieldDisabled('certificate') || form.isFormDisabled()}
                                    notShowInput={form.val('certificate')!=null}
                                    onFilesUploaded={this.fileUploaded}
                                    provideDisplayDocumentsUpdator={(updateFunction)=>{this.fileUploaderDisplayListUpdate = updateFunction;}}
                                    onFileDeleted={this.onfileDeleted}
                                />
                                {form.haveErrors(['certificate']) && <div className='text-danger'>{form.err('certificate')}</div>}
                            </div>
                        </div>
                    </>}
                    

                </div>
            </div>

            {this.state.confirmingAcceptance ? <>
                <div className='card border-primary border-1 mb-5 '>
                    <div className='card-body bg-primary bg-opacity-10 text-center'>
                        <p className='text-primary'>
                            <b>Please confirm all fields are filled in correctly.</b>
                        </p>
                        <div>
                            <button type="button" class="btn btn-secondary me-3" onClick={this.cancelAcceptance} disabled={this.state.sendingAcceptance}>
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary" onClick={this.confirmAcceptance} disabled={this.state.sendingAcceptance}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div> 
            </>
            :
            this.state.acceptanceSucceed?<>
                <div className='alert alert-success text-center mb-3'>
                This form has progressed to the next stage.
                </div>
                <div class="text-end">
                    <button type="button" class="btn btn-success" onClick={()=>this.lidInstallationForm.runAction('submit')} disabled={form.isFormDisabled()}>
                        Submit
                    </button>
                </div>
            </>
            
            : this.state.confirmingRejection?
            <>
                <div className='card border-danger border-1 mb-5 '>
                    <div className='card-body bg-danger bg-opacity-10 text-center'>
                        <p className='text-danger'>
                            <b>Please confirm that you are returning this form to DQP.</b>
                        </p>
                        <div>
                            <button type="button" class="btn btn-secondary me-3" onClick={this.cancelRejection} disabled={this.state.sendingRejection}>
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary" onClick={()=>this.lidInstallationForm.runAction('reject')} disabled={this.state.sendingRejection}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div> 
            </>
            :
            this.state.rejectionSucceed?
            <div className='alert alert-success text-center'>
                This form is returned to the DQP.
            </div>
            : this.state.confirmingSubmission?
            <>
                <div className='card border-primary border-1 mb-5 '>
                    <div className='card-body bg-primary bg-opacity-10 text-center'>
                        <p className='text-primary'>
                            <b>Please confirm that you would like to submit this form.</b><br/>
                            <span className='fs-80'>This link will no longer be available after submission</span>
                        </p>
                        <div>
                            <button type="button" class="btn btn-secondary me-3" onClick={this.cancelSubmission} disabled={this.state.sendingSubmission}>
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary" onClick={this.confirmSubmission} disabled={this.state.sendingSubmission}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div> 
            </>
            :
            this.state.submissionSucceed?
            <div className='alert alert-success text-center'>
                This form has been successfully submitted. An email will be sent to the DQP.
            </div>
            :
            form.val("lidInstallationStatus") == "ACCEPTED" ? 
            <>
            <div class="text-end">
                <button type="button" class="btn btn-success" onClick={()=>this.lidInstallationForm.runAction('submit')} disabled={form.isFormDisabled()}>
                    Submit
                </button>
            </div>
            </>
            :
            <div class="text-end">
                <button type="button" class="btn btn-danger me-3" onClick={this.onReturnClick} disabled={form.isFormDisabled()}>
                    Return to DQP
                </button>
                <button type="button" class="btn btn-success" onClick={()=>this.lidInstallationForm.runAction('accept')} disabled={form.isFormDisabled()}>
                    Proceed
                </button>
            </div>
            }


            
        </>
    }

    render(){
        return <>
        {this.renderMainContent()}
        <div class="modal fade" id="lid-confimation-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-danger">LID Selection Warning &amp; Acknowledgement</h5>
                        </div>
                        <div class="modal-body">
                        Please review and confirm you have selected the correct LID for this site registration. An incorrect LID model selection will result in a delay to the procurement process for up to 30 days due to manual intervention needed to resolve this DQP error.
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} data-bs-dismiss="modal">Acknowledge and Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default withRouter(LidInstallationVendor);
