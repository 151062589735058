import React from 'react';
import uiHelpers from '../../../../helpers/uiHelpers';
import { InputText, UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import AsyncSelect from 'react-select/async';
import * as apiCaller from '../../../../helpers/apiCaller'
import serviceUrls from '../../../../constants/serviceUrls';
import { nulToEmptyAndTrim } from '../../../../helpers/formatters';
import { isStringNullOrEmpty } from '../../../../helpers/validators';
import { AsteriskRed } from '../../../../components/tools/Others';

export default class MeterCommissionAdminTransfer  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedUser:null, 
            defaultOptions:[], 
            confirming:false,
            submitting:false,
            succeeded:false,
            comments:'',
            commentsError:null
        };
    }

    checkStatusAllow = () =>{
        switch(this.props.meterCommission.status) {
            case 'COMPLETED' :
            case 'DELETED' : 
                return false;
            case 'PENDING_SITE_REGO' : 
            case 'PENDING_REGO_APPROVAL' : 
            case 'PENDING_VALIDATION' : 
            case 'PENDING_VALIDATION_APPROVAL' : 
                return true;
            default: return false;
        }
    }

    loadOptions = async (
        inputValue,
        callback
      ) => {
        if(nulToEmptyAndTrim(inputValue)=='') return;
        const data = await apiCaller.post(serviceUrls.others.searchUsers,{keyword:inputValue});
        
        callback(data.result);
    };

    onUserSelectChanged = (user) => {
        this.setState({selectedUser:user})
    }

    onExecuteClick = () => {
        if(isStringNullOrEmpty(this.state.comments)) {
            this.setState({commentsError:'This is required'})
        } else {
            this.setState({confirming:true,commentsError:null});
        }
    }
    onCancelClick = () => {this.setState({confirming:false});}

    onConfirmClick = async () => {
        uiHelpers.showProgressOnTop('Transfering DQP...');
        this.setState({submitting:true})
        await apiCaller.post(serviceUrls.meterCommission.adminTransferDqp,{
            id:this.props.meterCommission.id, 
            toDqpId: this.state.selectedUser.id,
            comments:this.state.comments
        });
        await this.props.refreshPage();
        this.setState({succeeded:true, submitting:false});

    }

    async componentDidMount() {
        const data = await apiCaller.post(serviceUrls.others.searchUsers,{keyword:null});
        this.setState({defaultOptions:data.result});
    }

    render() {
        return <>
            {!this.checkStatusAllow() && <div className='alert alert-danger mt-4'>This form cannot be transfered under this status.</div>}
            {this.checkStatusAllow() && <>
                <div className='row mb-3'>
                    <div className='col-md-4 py-2'>
                        Current DQP
                    </div>
                    <div className='col-md-8'>
                        <UneditableTextInput value={<>{this.props.meterCommission.owner.firstname} {this.props.meterCommission.owner.lastname}</>} fieldExtraClass="bg-light"/>
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className='col-md-4 py-2'>
                    New DQP
                    </div>
                    <div className='col-md-8'>
                        <AsyncSelect 
                            cacheOptions
                            loadOptions={this.loadOptions} 
                            defaultOptions={this.state.defaultOptions}
                            onChange={this.onUserSelectChanged}
                            getOptionLabel={(o)=><>{o.firstname} {o.lastname} <i className='fs-80 text-muted'>({o.emailAddress})</i></>}
                            getOptionValue={(o)=>o.id}
                            isDisabled={this.state.confirming}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-md-4'>
                        Comments <AsteriskRed/>
                    </div>
                    <div className='col-md-8'>
                        <InputText
                            value={this.state.comments} 
                            error={this.state.commentsError} 
                            disabled={this.state.confirming || this.state.succeeded}
                            onChange={(e)=>this.setState({comments:e.target.value})} 
                        /> 
                    </div>
                </div>
                
                {this.state.succeeded && <div className='card border-success border-1 mb-3'>
                    <div className='card-body bg-success bg-opacity-10 text-center text-success'>
                        <b>The transfer has been completed successfully</b>
                    </div>
                </div>}
                {!this.state.confirming && !this.state.succeeded && <div className='row mb-3'>
                    <div className='col-md-4'/>
                    <div className='col-md-8'>
                        <button className='btn btn-primary' disabled={this.state.selectedUser==null?true:false} onClick={this.onExecuteClick}>Proceed</button>
                    </div>
                </div>}
                {this.state.confirming && !this.state.succeeded && <div className='card border-primary border-1 mb-3'>
                    <div className='card-body bg-primary bg-opacity-10 text-center'>
                        <p className='text-primary'>
                            <b>Please confirm that you want to continue with this transfer.</b>
                        </p>
                        <div>
                            <button type="button" class="btn btn-secondary me-3" onClick={this.onCancelClick} disabled={this.state.submitting}>
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary" onClick={this.onConfirmClick} disabled={this.state.submitting} >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>}
            </>}
        </>
    }
}