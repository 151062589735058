import React from 'react';
import HomeSearch from './common/HomeSearch';

class Home extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
      };
   }

   render() {
      return <>
         <h4><strong>OVERVIEW</strong></h4>
         <p>The Duly Qualified Person (DQP) Portal has been created as a platform for DQPs to request telemetry devices as well as submit information to WaterNSW on behalf of the water user contracting them to perform the work.</p>

         <div class="accordion mb-5" id="accordionInfo">
            <div class="accordion-item">
               <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                     Non-urban Metering - more information
                  </button>
               </h2>
               <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionInfo">
                  <div class="accordion-body">
                     <p>The following forms are available in this Portal to be submitted on behalf of the water user.</p>
                     <p><strong>Meter Compliance Process &ndash;</strong> for DQP's to request a telemetry device for new, existing or replacement meters. This shall also include meter commissioning and validation by a DQP</p>
                     <p><strong>Design Certificate &ndash;</strong> to outline the design of an open channel meter by a DQP</p>
                     <p><strong>Certificate of Accuracy &ndash; </strong>to demonstrate a meter has been checked by a DQP and the maximum permissible error of the meter does not exceed +/- 5% in the field</p>
                     <p><strong>Transitional Arrangements Report &ndash; </strong>For water users who want to keep meters that were installed before 1 April 2019. <em>Note: This report must include supporting documentation - a completed validation certificate</em></p>
                     {/**
                     <p><strong>Landholder Tracking Status Report &ndash; </strong>generate a report of all actions undertaken in the DQP Portal</p>
                      */}
                     <p><br /> <strong>Further information about telemetry and data logger requirements</strong></p>
                     <p>The Meter Compliance Process is used for requesting a telemetry device for new, existing or replacement meters on behalf of a water user. The details provided in the form will be sent securely to the Data Acquisition Service (DAS) to kick off the procurement process as well as create/amend the necessary workspace in the Data Acquisition Service.</p>
                     <p>Surface water pumps 500mm and above need to have telemetry installed from 1 December 2020. All other metered surface water works (except pumps less than 200 mm) need to have telemetry installed by the regional roll-out date.</p>
                     <p>Data collected by the DAS will assist the Natural Resources Access Regulator (NRAR), WaterNSW and the Department to undertake compliance and enforcement, billing and other water management activities.</p>
                     <p><a href="https://www.waternsw.com.au/__data/assets/pdf_file/0018/219321/Telemetry-metering-fact-sheet.pdf" target="blank">Read more about telemetry.</a><br /> <br /> <strong>Pattern-approved meter and maintenance requirements</strong></p>
                     <p>All meters installed after 1 April 2019 need to be pattern-approved. This applies to new installations and replacing an existing meter.</p>
                     <p>The <a href="https://water.dpie.nsw.gov.au/nsw-non-urban-water-metering/what-suppliers-of-metering-equipment-need-to-know" target="blank">Metering requirements and specifications</a> webpage on the Department of Planning, Industry and Environment (DPIE) site contains links to the latest</p>
                     <ul>
                        <li>Pattern-approved non-urban water meters published by the Murray&ndash;Darling Basin Authority (MDBA), and</li>
                        <li>Maintenance specifications that set out the maintenance needs to be carried out in relation to metering equipment</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="accordion-item">
               <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                     Floodplain Harvesting - more information
                  </button>
               </h2>
               <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionInfo">
                  <div class="accordion-body">
                     <p>The following Features are available in this Portal to be submitted/used on behalf of the water user.</p>
                     <p><strong>Site Telemetry Registration Form &ndash;</strong> for DQPs to request a telemetry device for new, existing or replacement storage devices or point of intake meters</p>
                     <p><strong>Survey Benchmarks Validation Certificate &ndash;</strong> to confirm a benchmark has been validated by a DQP</p>
                     <p><strong>Validation Certificate &ndash;</strong> to confirm a storage device or point of intake meter has been validated by a DQP</p>
                     <p><strong>Secondary Metering Equipment Validation Certificate &ndash;</strong> to confirm the secondary measurement method has been validated by a DQP</p>
                     <p><strong>Search for FPH Storage &ndash;</strong> search, view and download all current and previous rating table/storage curves.</p>
                     <p><strong>Update Rating Table / Storage curve form &ndash;</strong> provide an updated rating table/storage curve to replace the current table</p>
                     {/**
                     <p><strong>Landholder Tracking Status Report &ndash;</strong> generate a report of all actions undertaken in the DQP Portal</p>
                     */}
                     <p><br /><strong>Further information about telemetry and data logger requirements</strong></p>
                     <p>The Site Telemetry Registration Form is used for requesting a telemetry device for new, existing or replacement storage devices/ point of intake meters on behalf of a water user. The details provided in the form will be sent securely to the Data Acquisition Service (DAS) to kick off the procurement process as well as create/amend the necessary workspace in the Data Acquisition Service.</p>
                     <p>Data collected by the DAS will assist the Natural Resources Access Regulator (NRAR), WaterNSW and the Department to undertake compliance and enforcement, billing and other water management activities.</p>
                     <p><a href="https://www.industry.nsw.gov.au/water/plans-programs/healthy-floodplains-project/measurement" target="blank">Read more about Floodplain Harvesting Measurement.</a></p>
                  </div>
               </div>
            </div>
         </div>

         <HomeSearch/>
         
         <div class="card mb-5">
            <div class="card-header bg-primary text-white">
               <strong>Helpful links</strong>
            </div>
            <div class="card-body">
               <ul>
                  <li>
                     <a href="https://nswdpie.tfaforms.net/267" target="_blank">Metering Guidance Tool</a>
                  </li>
                  <li>
                     <a href="https://waterregister.waternsw.com.au/water-register-frame" target="_blank">WaterNSW Water Register</a>
                  </li>
                  <li>
                     <a href="https://www.irrigationaustralia.com.au/Web/COIE/Certification/Metering-Policy---Regulations.aspx?hkey=f8129c2a-8bf3-4b51-af97-d4bb8f5cef9b" target="_blank">List of Pattern-Approved Meters (MDBA)</a>
                  </li>
                  <li>
                     <a href="https://www.irrigationaustralia.com.au/documents/item/945" target="_blank">NSW non-urban water metering (IAL)</a>
                  </li>
               </ul>
            </div>
         </div>

         <h4>Report suspicious activity</h4>
         <p>To make a confidential report of any suspicious activities to the Natural Access Resource Regulator (NRAR):</p>
         <ul>
            <li>use the <a href="https://www.nrar.nsw.gov.au/report-suspicious-water-activities/report-suspicious-activity" target="blank">online form</a> or</li>
            <li>phone 1800 633 362</li>
         </ul>
         <p>If you experience any issues with this portal, please contact us via "Submit an Enquiry" on top right section of every page.</p>

      </>;
   }
}

export default Home;