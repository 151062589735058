import React from 'react';
import { australianMobileValidator, FlatForm, mandatoryValidator, positiveWholeNumberValidator, preConditionToValidate, txtMandatoryValidator } from '../../../helpers/FormHelper';
import serviceUrls from '../../../constants/serviceUrls';
import uiHelpers from '../../../helpers/uiHelpers';
import { AsteriskRed, LoadingPage } from '../../../components/tools/Others';
import * as apiCaller from '../../../helpers/apiCaller'
import { SearchIcon } from '../../../components/tools/Icons';
import FileUploader from '../../../components/common/FileUploader';

const refreshOptions = async (form)=>{

    form.disableForm();
    uiHelpers.showProgressOnTop('Please wait...');

    await apiCaller.post(
        serviceUrls.others.getContactFormOptions,
        {
            numrOrFph: form.val('numrOrFph')
        }
    )
    .then((result)=>{
        form.setOptions({
            formEnquiryType:result.formEnquiryTypeOptions,
            formType:result.formTypeOptions
        })
    }).finally(()=>{
        form.enableForm();
        uiHelpers.hideProgressOnTop();
    });
    
}


const initContactForm = (context, findFormInfoAction, submitAction) => {
    const result = new FlatForm('contactForm', context);
    result.initialise([
        'dqpName',
        'dqpEmail',
        'contactMobile',
        'numrOrFph',
        'formEnquiryType',
        'existingFormRelated',
        'formType',
        'formId',
        'approvalNumber',
        'esid',
        'meterNumber',
        'roi',
        'description',
        'documents'
    ])
    .setValueChangedValidators({
        formId:[positiveWholeNumberValidator('Not valid ID (must be possitive whole number)')],
        esid:[positiveWholeNumberValidator('This is not a valid ESID number')],
        meterNumber:[positiveWholeNumberValidator('This is not a valid meter number')],
    })
    .setPostChangeActions({
        numrOrFph:(value, values, form)=>{
            form.vals({
                formEnquiryType:null,
                existingFormRelated:null,
                formType:null,
                formId:'',
                approvalNumber:'',
                esid:'',
                meterNumber:'',
                roi:''
            },false);
            form.errs({
                formEnquiryType:null,
                existingFormRelated:null,
                formType:null,
                formId:null,
                approvalNumber:null,
                esid:null,
                meterNumber:'',
                roi:''
            },false);
            refreshOptions(form);
        },
        existingFormRelated:(val,vals,form)=>{
            form.vals({
                formType:null,
                formId:''
            },false);
            form.errs({
                formType:null,
                formId:null
            },false);
        }
    })
    .defineAction('findFormInfo',findFormInfoAction,{formId:[txtMandatoryValidator('Required'),positiveWholeNumberValidator('Not valid ID (must be possitive whole number)')]})
    .defineAction('submit',submitAction,{
        contactMobile:[txtMandatoryValidator('Required'),australianMobileValidator('Not a valid Australian mobile number')],
        numrOrFph:[mandatoryValidator("Required")],
        formEnquiryType:[preConditionToValidate((val, vals)=>vals.numrOrFph, mandatoryValidator("Required"))],
        existingFormRelated:[preConditionToValidate((val, vals)=>vals.numrOrFph, mandatoryValidator("Required"))],
        formType:[preConditionToValidate((val, vals)=>vals.existingFormRelated===true, mandatoryValidator("Required"))],
        formId:[preConditionToValidate((val, vals)=>vals.formType, txtMandatoryValidator("Required")),positiveWholeNumberValidator('Not valid ID (must be possitive whole number)')],
        
        approvalNumber:[preConditionToValidate((val, vals)=>vals.formEnquiryType && vals.formEnquiryType.name != 'General Enquiry', txtMandatoryValidator("Required"))],
        esid:[preConditionToValidate((val, vals)=>vals.formEnquiryType && vals.formEnquiryType.name != 'General Enquiry', txtMandatoryValidator("Required")), positiveWholeNumberValidator('This is not a valid ESID number')],
        
        meterNumber:[positiveWholeNumberValidator('This is not a valid meter number')],
        description:[txtMandatoryValidator('Required')]
    })
        
    ;
    
    
    return result;
}

const initState = {
    initialised:false,
    confirming:false,
    submitting:false,
    submitted:false
}
class ContactForm extends React.Component {

    contactForm;
    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
        this.contactForm = initContactForm(this, this.findFormInfo, this.submit);
    }

    async componentDidMount(){

        uiHelpers.showProgressOnTop('Please wait...');
        this.contactForm.disableForm();
        await apiCaller.post(serviceUrls.others.initContactForm,{})
        .then((data)=>{
            this.contactForm.vals(data.contactUsForm.fields, false);
            this.contactForm.setOptions(data.contactUsForm.optionsForFields, false);
            this.contactForm.enableForm(()=>this.setState({initialised:true}));
        }).finally(()=>{
            uiHelpers.hideProgressOnTop();
            window.scrollTo(0, 0);
        });
    }

    submit = async () =>{
        this.setState({confirming:true},this.contactForm.disableForm)
    }

    cancelSubmission = async () => {
        this.setState({confirming:false},this.contactForm.enableForm)
    }

    confirmSubmission = async () => {
        this.contactForm.disableForm(()=>this.setState({submitting:true}));
        uiHelpers.showProgressOnTop('Submitting...');
        await apiCaller.post(serviceUrls.others.submitContactForm,{contactUsForm:this.contactForm.vals()})
        .then((data)=>{
            if(data.succeeded) {
                this.setState({submitted:true, submitting:false, confirming:false})
            }
        }).finally(()=>{
            uiHelpers.hideProgressOnTop();
        });
        
    }

    findFormInfo = async()=>{
        const form = this.contactForm;
        uiHelpers.showProgressOnTop('Please wait...');
        form.disableForm();
        await apiCaller.post(serviceUrls.others.contactFormFindExistingForm,{numrOrFph:form.val('numrOrFph'), formType:form.val('formType'), formId:form.val('formId')})
        .then((data)=>{
            form.vals(data, false);
        }).finally(()=>{
            uiHelpers.hideProgressOnTop();
            form.enableForm();
        });
    }

    fileUploaded = async(files)=>{
        const form = this.contactForm;
        const documents = form.val('documents');
        files.forEach(f=>{
            documents.push({
                documentId:f.id,
                fileName:f.fileName,
                fileType: f.fileType,
                fileUrl: f.fileUrl,
                fileSize: f.fileSize,
                fileOriginalName: f.fileOriginalName,
                fileGUID: f.fileGUID
            })
        });
        form.vals({documents}, true, this.refresh);
    }

    onfileDeleted=(file)=>{
        const form = this.contactForm;
        const documents = form.val('documents');
        for(var i = 0; i< documents.length; i++){
            if(file===documents[i]) {
                documents.splice(i, 1);
                break;
            }
        }
        form.vals({documents}, true, this.refresh);
     }

    fileUploaderDisplayListUpdate;
    fileUploaderFilesUploaded = (files) => {
        const form = this.contactForm;
        const documents = form.val('documents');
        files.forEach(f=>{
            documents.push({
                id:null,
                description:f.description,
                documentType:this.props.documentType,
                documentId:f.id,
                fileName:f.fileName,
                fileType: f.fileType,
                fileUrl: f.fileUrl,
                fileSize: f.fileSize,
                fileOriginalName: f.fileOriginalName,
                fileGUID: f.fileGUID
            })
        });
        form.vals({documents}, true, this.refresh);
    }

    refresh = () => {
        const form = this.contactForm;
        this.fileUploaderDisplayListUpdate(form.val('documents'));
    }

    render() {
        if(!this.state.initialised) return <LoadingPage />;
        const form = this.contactForm;
        return <>
            <h3 class="mb-3">
                Fill this Form to Contact Us
            </h3>
            <div className='card mb-3'>
                <div className='card-body'>
                    {form.renderUneditableTextInputRow('dqpName',<>Your Name</>,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow('dqpEmail',<>Your Email</>,{rowClasses:'mb-3'})}
                    {form.renderTextBoxRow('contactMobile',<>Your Mobile No. <AsteriskRed/></>,{rowClasses:'mb-3'})}

                    {form.renderDropDownRow('numrOrFph',<>NUMR or FPH <AsteriskRed/></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})}
                    {form.val('numrOrFph') && <>
                        {form.renderDropDownRow('formEnquiryType',<>Enquiry Type <AsteriskRed/></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})}
                        <div className='card card-body mb-3'>
                            {form.renderYesNoOptionsRow('existingFormRelated',<>Is this relating to an existing form? <AsteriskRed/></>,{})}
                            {form.val('existingFormRelated')===true && <>
                                {form.renderDropDownRow('formType',<>Form Type <AsteriskRed/></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mt-3'})}
                                {form.val('formType') && <div className='row mt-3'>
                                    <div className='col-md-4 py-2'>
                                        Form ID <AsteriskRed/>:
                                    </div>
                                    <div className='col-md-2'>
                                        {form.renderTextBox('formId')}
                                    </div>
                                    <div className='col-md-6 pt-1'>
                                        <button className='btn btn-sm btn-outline-primary' disabled={form.haveErrors(['formId']) || form.isFormDisabled()} onClick={()=>form.runAction('findFormInfo')} ><SearchIcon/></button>
                                    </div>
                                </div>}
                            </>}
                        </div>

                        {form.val('numrOrFph').key=='NUMR' && <>
                            {form.renderTextBoxRow('approvalNumber',<>Approval No. {form.val("formEnquiryType") && form.val("formEnquiryType").name != "General Enquiry" && <AsteriskRed/>}</>,{rowClasses:'mb-3'})}
                            {form.renderTextBoxRow('esid',<>ESID {form.val("formEnquiryType") && form.val("formEnquiryType").name != "General Enquiry" && <AsteriskRed/>}</>,{rowClasses:'mb-3',fieldWidth:4})}
                            {form.renderTextBoxRow('meterNumber',<>Meter No.</>,{rowClasses:'mb-3',fieldWidth:4})}
                        </>}
                        {form.val('numrOrFph').key=='FPH' && <>
                            {form.renderTextBoxRow('roi',<>ROI</>,{rowClasses:'mb-3'})}
                            {form.renderTextBoxRow('approvalNumber',<>Approval No.</>,{rowClasses:'mb-3'})}
                        </>}
                    </>}
                    <hr/>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Enquiry / Issue / Error description <AsteriskRed/>
                        </div>
                        <div className='col-md-8'>
                            {form.renderTextArea('description')}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-4 py-2'>
                        Attachments
                        </div>
                        <div className='col-md-8'>
                            <FileUploader
                                maxFileSizeMB={10}
                                exceedFileSizeErrorMsg = "Your file is too big"
                                disabled={form.isFieldDisabled('documents') || form.isFormDisabled()}
                                notShowInput={false}
                                onFilesUploaded={this.fileUploaded}
                                provideDisplayDocumentsUpdator={(updateFunction)=>{this.fileUploaderDisplayListUpdate = updateFunction;}}
                                onFileDeleted={this.onfileDeleted}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {this.state.confirming ? 
            <div className='card border-primary border-1 mb-5 '>
                <div className='card-body bg-primary bg-opacity-10 text-center'>
                    <p className='text-primary'>
                        <b>Please confirm that you would like to submit this form</b>
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.cancelSubmission} disabled={this.state.submitting}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.confirmSubmission} disabled={this.state.submitting}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div> 
            : 
            this.state.submitted ? 
            <div className='card border-success border-1 mb-5 '>
                <div className='card-body bg-success bg-opacity-10 text-center text-success'>
                    <b>Your enquiry is submitted to us. Please check your emails for further updates.</b>
                </div>
            </div> 
            :
            <div className='text-end'>
                <button type="button" class="btn btn-success" onClick={()=>form.runAction('submit')} disabled={form.isFormDisabled()}>
                    Submit
                </button>
            </div>}
            
            
        </>;
    }
}

export default ContactForm;