import { FlatForm, latitudeNswValidator , longitudeNswValidator, mandatoryValidator, positiveNumberValidator, positiveWholeNumberValidator, preConditionToValidate, txtMandatoryValidator} from "../../../helpers/FormHelper";
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import uiHelpers from '../../../helpers/uiHelpers';
import { toStringIfNotNull } from "../../../helpers/formatters";
import { replacingLidReasonsRequireRef } from "../../../constants/MeterFormConstants";

const refreshLidOptions = async (form)=>{
    
    const telemetryType = form.val('telemetryType');

    form.disableForm();
    uiHelpers.showProgressOnTop('Refreshing LID options...');
    await apiCaller.post(
        serviceUrls.meterFormCommon.getLidOptions,
        {
            isLidTelemetry: form.val('isLidTelemetry'),
            installationTypeId: form.val('installationType')?form.val('installationType').id:null,
            telemetryTypeId: telemetryType?telemetryType.id:null
        }
    )
    .then((result)=>{
        form.setOptions({lid:result.lidOptions},false);
    }).finally(()=>{
        form.enableForm();
        uiHelpers.hideProgressOnTop();
    });
}

const refresWorksAndMeterOptions = async (form)=>{
    
    const extractionSite = form.val('extractionSite');

    form.disableForm();
    uiHelpers.showProgressOnTop('Getting meters...');
    
    await apiCaller.post(
        serviceUrls.meterFormCommon.getWorksAndMetersByWorksiteId,
        {worksiteId:extractionSite.worksiteId}
    )
    .then((result)=>{
        form.setOptions({
            existingWasMeter:result.meters,
            replacingWasMeter:result.meters,
            dlwcLot:result.lots
        },false);
    }).finally(()=>{
        form.enableForm();
        uiHelpers.hideProgressOnTop();
    });
}

const refreshLidsWithSameApproval = async (form)=>{

    form.disableForm();
    uiHelpers.showProgressOnTop('Refreshing LIDs under approval...');

    await apiCaller.post(
        serviceUrls.meterFormCommon.getExistingLidsForApproval,
        {
            approvalNumber:form.val('workApproval'),
            lid:form.val('lid')
        }
    )
    .then((result)=>{
        form.setOptions({existingLid:result.lids})
    }).finally(()=>{
        form.enableForm();
        uiHelpers.hideProgressOnTop();
    });
    
}

export const getSiteRegistrationForm = (formName, context, saveFormAction, submitFormAction, openLidConfirmationModal,openInstallationTypeAcknowledgementModal) => {

    const result = new FlatForm(formName, context);
    const changeAndSaveValidators = {
        latitude : [latitudeNswValidator],
        longitude:[longitudeNswValidator],
        meterSize:[positiveWholeNumberValidator('Please enter a valid meter size (between 10 and 9999, no decimals values allowed)')],
        replacingMeterReading:[positiveNumberValidator('Please enter a valid number')],
        customersPumpNumber:[positiveWholeNumberValidator('Please enter a valid number')],
    };
    result.initialise([
        "id",
        "formStatus",
        "formType",
        "workApproval",
        "installationType",
        "isLidTelemetry",
        "telemetryType",
        "lid",
        "manufacturerAndModel",
        "otherManufacturerAndModelText",
        "meterSize",
        "extractionSite",
        "works",
        "latitude",
        "longitude",
        "existingWasMeter",
        "existingMeter",
        "replacingWasMeter",
        "replacingMeter",
        "isFirstMeterForLID",
        "existingLid",
        "replacingMeterFinal",
        "replacingMeterReading",
        "replacingMeterReadingDate",
        "replacingMeterS91iRef",
        "replaceMeterNumber",
        "dqpNotes",
        "replacingLidReason",
        "replacingLidS91iRef",

        "dlwcProperty",
        "dlwcLot",
        "customersSiteName",
        "customersPumpNumber",
        "lidVendor",
        "formVersion"

    ])
    .setValueChangedValidators({...changeAndSaveValidators,existingWasMeter:[(val,vals)=>{
        if(!vals.installationType || !val) return null;
        switch(vals.installationType.key) {
            case 'E_M_N_L':
                
                if(val.meter) return "LID already exist under this meter";
                break;
            case 'E_M_R_L':
                if(!val.meter) return "No LID under this meter to be replaced";
                if(val.meter.status!='ACTIVE') return "The meter is on temporary hold (no transactions allowed). Please contact WaterNSW via enquiries email for more information.";
                if(val.meter.lidAllowMultiple) return `"${val.meter.lidName}" is currently not supported to be replaced in our system`;
                break;
            case 'S_R':
                if(!val.meter) return "This meter cannot be re-validated as there is no LID";
                if(val.meter.status!='ACTIVE') return "The meter is on temporary hold (no transactions allowed). Please contact WaterNSW via enquiries email for more information.";
                break;
        }
    }],existingLid:[
        (val)=>{
            if(val==null) return null;
            if(val.meters.length>=4) return "We have maxiumn 4 meters to be allowed to connect to one LID";
        }
    ]})
    .setPostChangeActions({
        manufacturerAndModel:(val,vals,form)=>{
            form.vals({otherManufacturerAndModelText:''}, false);
            form.errs({otherManufacturerAndModelText:null});
        },
        isLidTelemetry: (value, values,form)=>{
            form.vals({lid:null, telemetryType:null,isFirstMeterForLID:null,lid:null,existingLid:null}, false);
            form.errs({lid:null, telemetryType:null,isFirstMeterForLID:null,lid:null,existingLid:null}, false);
            if(values.formVersion < 3)
                if(value===false || (value === true && values.telemetryType)) refreshLidOptions(form);
        },
        telemetryType: (value, values, form)=>{
            form.vals({lid:null,isFirstMeterForLID:null,existingLid:null}, false);
            form.errs({lid:null,isFirstMeterForLID:null,existingLid:null}, false);
            refreshLidOptions(form);
        },
        extractionSite:(value, values, form)=>{
            form.vals({
                latitude:value.esLat?toStringIfNotNull(value.esLat):null,
                longitude:value.esLong?toStringIfNotNull(value.esLong):null,
                existingMeter:null,
                replacingMeter:null,
                replaceMeterNumber:null,
                existingWasMeter:null,
                replacingWasMeter:null,
                replacingMeterFinal:null,
                replacingMeterReading:null,
                replacingMeterReadingDate:null,
                replacingMeterS91iRef:null,
                replacingLidReason:null,
                replacingLidS91iRef:null,
                dlwcLot:null
            },false);
            form.errs({
                latitude:null,
                longitude:null,
                existingMeter:null,
                replacingMeter:null,
                replaceMeterNumber:null,
                existingWasMeter:null,
                replacingWasMeter:null,
                replacingMeterFinal:null,
                replacingMeterReading:null,
                replacingMeterReadingDate:null,
                replacingMeterS91iRef:null,
                replacingLidReason:null,
                replacingLidS91iRef:null,
                dlwcLot:null
            },false);
            refresWorksAndMeterOptions(form);
        },
        replacingWasMeter:(val, vals,form) => {
            form.vals({
                replacingMeter:val.meter,
                replaceMeterNumber:val.meterNumber,
                replacingMeterFinal:null,
                replacingMeterReading:null,
                replacingMeterReadingDate:null,
                replacingMeterS91iRef:null
            },false);
            form.errs({
                latitude:null,
                longitude:null,
                replaceMeterNumber:null,
                replacingMeter:null,
                replacingMeterFinal:null,
                replacingMeterReading:null,
                replacingMeterReadingDate:null,
                replacingMeterS91iRef:null
            });
        },
        replacingMeterFinal:(val, vals,form)=>{
            form.vals({
                replacingMeterReading:null,
                replacingMeterReadingDate:null,
                replacingMeterS91iRef:null
            },false);
            form.errs({
                replacingMeterReading:null,
                replacingMeterReadingDate:null,
                replacingMeterS91iRef:null
            });
        },
        existingWasMeter:(val,vals,form) =>{
            form.vals({
                latitude:val.latitude?toStringIfNotNull(val.latitude):vals.extractionSite?toStringIfNotNull(vals.extractionSite.esLat):null,
                longitude:val.longitude?toStringIfNotNull(val.longitude):vals.extractionSite?toStringIfNotNull(vals.extractionSite.esLong):null,
                existingMeter:val.meter,
                replacingLidReason:null,
                replacingLidS91iRef:null
            },false);
            form.errs({
                latitude:null,
                longitude:null,
                existingMeter:null,
                replacingLidReason:null,
                replacingLidS91iRef:null
            });
        },
        replacingLidReason:(val,vals,form) =>{
            form.errs({
                replacingLidS91iRef:null
            });
        },
        installationType:(value, values, form)=>{
            openInstallationTypeAcknowledgementModal();
            form.vals({
                existingMeter:null,
                replacingMeter:null,
                manufacturerAndModel:null,
                otherManufacturerAndModelText:'',
                meterSize:'',
                isLidTelemetry:null,
                telemetryType:null,
                lid:null,
                isFirstMeterForLID:null,
                existingLid:null,
                latitude:'',
                longitude:'',
                existingWasMeter:null,
                existingMeter:null,
                replacingWasMeter:null,
                replacingMeter:null,
                isFirstMeterForLID:null,
                replaceMeterNumber:null,
                replacingMeterFinal:null,
                replacingMeterReading:null,
                replacingMeterReadingDate:null,
                replacingMeterS91iRef:null,
                replacingLidReason:null,
                replacingLidS91iRef:null,
                lidVendor:null
            },false);
            form.errs({
                existingMeter:null,
                replacingMeter:null,
                manufacturerAndModel:null,
                otherManufacturerAndModelText:null,
                meterSize:null,
                isLidTelemetry:null,
                telemetryType:null,
                lid:null,
                isFirstMeterForLID:null,
                existingLid:null,
                latitude:'',
                longitude:'',
                existingWasMeter:null,
                existingMeter:null,
                replacingWasMeter:null,
                replacingMeter:null,
                isFirstMeterForLID:null,
                replaceMeterNumber:null,
                replacingMeterFinal:null,
                replacingMeterReading:null,
                replacingMeterReadingDate:null,
                replacingMeterS91iRef:null,
                replacingLidReason:null,
                replacingLidS91iRef:null,
                lidVendor:null
            });
        },
        lid: (value, values, form) =>{

            openLidConfirmationModal();
            form.vals({
                isFirstMeterForLID:null,
                existingLid:null
            },false);
            form.errs({
                isFirstMeterForLID:null,
                existingLid:null
            },false);
            form.setOptions({existingLid:[]})
        },
        isFirstMeterForLID:(value, values, form)=>{
            if(form.val('isFirstMeterForLID')===false) {
                refreshLidsWithSameApproval(form);
            } else {
                form.vals({existingLid:null},false);
                form.errs({existingLid:null},false);
                form.setOptions({existingLid:[]})
            }
        }
    })
    .defineAction('save',saveFormAction,changeAndSaveValidators)
    .defineAction('submit',submitFormAction,{
        installationType : [mandatoryValidator("This is required")],
        extractionSite : [mandatoryValidator("This is required")],
        works : [mandatoryValidator("This is required")],
        isLidTelemetry : [preConditionToValidate((val, vals)=>{return !(vals.installationType==null) && !vals.installationType.key=='S_R'},mandatoryValidator("This is required"))],
        telemetryType :[preConditionToValidate((val, vals)=>{return vals.formVersion < 3 && vals.isLidTelemetry===true},mandatoryValidator("This is required"))],
        lid:[preConditionToValidate((val, vals)=>{return !(vals.installationType==null || vals.installationType.key=='S_R' || vals.formVersion >= 3)},mandatoryValidator("This is required"))],
        manufacturerAndModel:[preConditionToValidate((val,vals)=>!(vals.installationType==null || vals.installationType.key=='E_M_R_L' || vals.installationType.key=='S_R'),mandatoryValidator("This is required"))],
        meterSize:[positiveWholeNumberValidator('Please enter a valid meter size (between 10 and 9999, no decimals values allowed)')],
        latitude:[txtMandatoryValidator("This is required"),latitudeNswValidator],
        longitude:[txtMandatoryValidator("This is required"),longitudeNswValidator],
        otherManufacturerAndModelText:[preConditionToValidate((val,vals)=>(vals.manufacturerAndModel && vals.manufacturerAndModel.key=="Other"),txtMandatoryValidator("This is required"))],
        existingLid:[(val,vals)=>{
            if(vals.isFirstMeterForLID===false) {
                if(val==null) return "This is required"
                if(val.meters.length >= 4) return "We have maxiumn 4 meters to be allowed to connect to one LID";
            }
            return null;
        }],
        existingWasMeter:[(val,vals)=>{
            if(!vals.installationType) return null;
            switch(vals.installationType.key) {
                case 'E_M_N_L':
                    if(val==null) return "This is required";
                    if(val.meter) return "LID already exist under this meter";
                    break;
                case 'E_M_R_L':
                    if(val==null) return "This is required";
                    if(!val.meter) return "No LID under this meter to be replaced";
                    if(val.meter.lidAllowMultiple) return `"${val.meter.lidName}" is currently not supported to be replaced in our system`;
                    if(val.meter.status!='ACTIVE') return "The meter is on temporary hold (no transactions allowed). Please contact WaterNSW via enquiries email for more information.";
                    break;
                case 'S_R':
                    if(val==null) return "This is required";
                    if(!val.meter) return "This meter cannot be re-validated as there is no LID";
                    if(val.meter.status!='ACTIVE') return "The meter is on temporary hold (no transactions allowed). Please contact WaterNSW via enquiries email for more information.";
                    break;
            }
        }],
        replacingMeterFinal:[preConditionToValidate((val, vals)=>{return vals.replacingWasMeter!=null},mandatoryValidator("This is required"))],
        replacingMeterS91iRef:[preConditionToValidate((val, vals)=>{return vals.replacingMeterFinal===false},txtMandatoryValidator("This is required"))],
        replacingMeterReading:[preConditionToValidate((val, vals)=>{return vals.replacingMeterFinal===true},txtMandatoryValidator("This is required")),positiveNumberValidator('Please enter a valid number')],
        replacingMeterReadingDate:[preConditionToValidate((val, vals)=>{return vals.replacingMeterFinal===true},mandatoryValidator("This is required"))],

        replacingLidReason:[preConditionToValidate((val, vals)=>{return vals.installationType && vals.installationType.key=="E_M_R_L"},mandatoryValidator("This is required"))],
        replacingLidS91iRef:[preConditionToValidate((val, vals)=>{return vals.replacingLidReason && replacingLidReasonsRequireRef.includes(vals.replacingLidReason.value)},txtMandatoryValidator("This is required"))],
        
        dqpNotes:[txtMandatoryValidator("This is required")],
        customersPumpNumber:[positiveWholeNumberValidator('Please enter a valid number')],
        //customersSiteName : [txtMandatoryValidator('This is required')],
        lidVendor: [preConditionToValidate((val, vals)=>{return !(vals.installationType==null || vals.installationType.key=='S_R' || vals.formVersion < 3)},mandatoryValidator("This is required"))],
    })
    ;
    return result;
}