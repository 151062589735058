import { FlatForm,  mandatoryValidator } from "../../../helpers/FormHelper";


export const getLidInstallationForm = (formName, context) => {

    const result = new FlatForm(formName, context);

    result.initialise([
        "id",
        "installationType",
        "isLidTelemetry",
        "telemetryType",
        "lid",
        "isFirstMeterForLID",
        "existingLid",
        "lidVendor",
        "formVersion",
        "lidInstallationStatus",
        "meterType",
        "meterScalingFactor"
    ])
    .setValueChangedValidators({})
    .setPostChangeActions({})
    ;
    return result;
}