import React from 'react';
import { Route, Switch } from "react-router-dom";
import SiteAuditStart from './components/SiteAuditStart';
import SiteAuditForm from './components/SiteAuditForm';




class Routes  extends React.Component {

    render(){        
        return <Switch>
            <Route exact path="/site-audit/start" component={SiteAuditStart} />
            <Route exact path="/site-audit/:id/form" component={SiteAuditForm} />
        </Switch>
    }
}

export default Routes;