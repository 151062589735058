import React from 'react';
import { UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import { convertMeterComissionRole, convertMeterCommissionStatus } from '../../../../helpers/converters';

export default class MeterCommissionHeading  extends React.Component {

    render() {
        const meterCommission = this.props.meterCommission;
        return <>
            <h3 class="mb-1">
                Meter Compliance Process
                
            </h3>
            <p>
                {meterCommission.consent.governmentOwn===true && <i className='text-primary fs-80'>(Government-owned consent in use)</i>}
                {meterCommission.consent.governmentOwn===false && <i className='text-primary fs-80'>(Private-owned consent in use)</i>}
            </p>
            <div className='d-none'>Form Version: {meterCommission.formVersion}</div>
            <div className='row'>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Form ID</>}
                        value={<>
                            {meterCommission.id}
                        </>}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Form Status</>}
                        value={convertMeterCommissionStatus(meterCommission.status)}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label="DQP"
                        value={meterCommission.owner.firstname?<>{meterCommission.owner.firstname} {meterCommission.owner.lastname}</>:null}
                    />
                </div>
                {this.props.renderWorkStatus && <div className='col-md-3 mb-3'>{this.props.renderWorkStatus()}</div>}
            </div>
            {meterCommission.meter && <>
                <div className='row'>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput
                            label={<>Meter <i className='fs-80'>(DAS workspace ID)</i></>}
                            value={meterCommission.meter.meterSiteId}
                        />
                    </div>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput
                            label={<>Source ID</>}
                            value={meterCommission.meter.meterId}
                        />
                    </div>
                </div>
            </>}
        </>
    }
}