import React from 'react';
import {  Lock, LockFill, Pen, SortDown, SortUp} from '../../../components/tools/Icons'
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import Select from 'react-select';
import moment from 'moment';
import { Redirect  } from "react-router-dom";
import { InputText } from '../../../components/tools/BootstrapControls';
import { isPositiveWholeNumber } from '../../../helpers/validators';
import UsersDropDown from './common/UsersDropDown';
import { convertMeterCommissionStatus, convertMeterSiteAuditFormStatus } from '../../../helpers/converters';






const initStateSiteAudit = {
    callingBackend:false
}
class AssignMeterSiteAudit extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initStateSiteAudit));
    }

    async componentDidMount() {

    }
    
    save = async () => {
        if(!this.state.editApprovingOfficerForm.approvingOfficer) {
            this.state.editApprovingOfficerForm.approvingOfficerError = "Please select the approving officer"
            this.forceUpdate();
            return;
        } else {
            this.state.editApprovingOfficerForm.approvingOfficerError = null;
        }

        uiHelpers.showProgressOnTop('Saving...');
        this.setState(Object.assign(this.state,{callingBackend:true}));
        await apiCaller.post(serviceUrls.meterSiteAuditForm.assignApprovingOfficer,{
            id:this.state.editApprovingOfficerForm.id,
            approvingOfficer:this.state.editApprovingOfficerForm.approvingOfficer
        })
        .then((data)=>{
            if(data.succeed){
                this.setState(Object.assign(this.state,{callingBackend:false,editApprovingOfficerForm:null}));
                this.props.refreshPage();
            }
        })
    }

    openSiteAuditForm  = (meterSiteAuditForm) => {
        this.setState({redirectTo:`/site-audit/${meterSiteAuditForm.id}/form`});return;    
    }

    convertFormStatus = (val) =>{
        return convertMeterSiteAuditFormStatus(val)
    }


    editApprovingOfficer = (meterSiteAuditForm) => {
        this.state.editApprovingOfficerForm = {
            id : meterSiteAuditForm.id,
            approvingOfficer: null,
            approvingOfficerError : null
        }
        this.forceUpdate();
    }

    cancelEdit = () => {
        this.state.editApprovingOfficerForm =null;
        this.forceUpdate();
    }

    render() {
        const records = this.props.records;
        const approvingOfficers = this.props.approvingOfficers;
        
        return <>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo}  />}
            <div class="table-responsive-xxl">
                <table class="table table-striped" style={{"font-size":"0.8em"}}>
                    <thead>
                        <tr>
                            
                            <th scope="col" style={{width:"120px"}}>
                                Created 
                            </th>
                            <th scope="col" style={{width:"80px"}}>
                                #ID 
                            </th>
                            <th scope="col" style={{width:"230px"}}>
                                Approval-ESID-Meter No. 
                            </th>
                            <th scope="col" style={{width:"200px"}}>
                                Form Status
                            </th>
                            <th scope="col" style={{width:"200px"}}>
                                Auditor
                            </th>
                            <th scope="col">
                                Approving Officer
                            </th>
                            <th scope="col" style={{width:"160px"}}></th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {records.map(m=>{
                            return <tr scope="row" className={this.state.editApprovingOfficerForm && this.state.editApprovingOfficerForm.id != m.id ? "opacity-25" : ""}>
                                
                                <td>{moment((new Date(m.createdDate))).format('DD/MM/YYYY')}</td>
                                <td>{m.id}</td>
                                <td>
                                    {m.approval}{m.esid?`-${m.esid}`:''}{m.meterNumber?`-${m.meterNumber}`:''}
                                </td>
                                <td>
                                    {this.convertFormStatus(m.formStatus)}
                                </td>
                                <td>{m.auditorFirstname} {m.auditorLastname}</td>
                                <td>
                                    {this.state.editApprovingOfficerForm && this.state.editApprovingOfficerForm.id == m.id ? <div>
                                        <div className='mb-2'>
                                            <Select
                                                isDisabled={this.state.callingBackend}
                                                className={this.state.editApprovingOfficerForm.approvingOfficerError ? "react-select-invalid" : ""}
                                                getOptionLabel={(u)=><>{u.firstname} {u.lastname} <span className='text-muted fs-80'>({u.role=='CDO'?'CDO':'Super Admin'})</span></>}
                                                getOptionValue={(u)=>u.id}
                                                options={approvingOfficers}
                                                onChange={(u)=>{this.state.editApprovingOfficerForm.approvingOfficer = u; this.forceUpdate()}}
                                                value={this.state.editApprovingOfficerForm.approvingOfficer}
                                            />
                                            {this.state.editApprovingOfficerForm.approvingOfficerError && <div className='invalid-feedback'>{this.state.editApprovingOfficerForm.approvingOfficerError}</div>}
                                        </div>
                                        <button className='btn btn-primary me-2' disabled={this.state.callingBackend} onClick={this.save}>Save</button>
                                        <button className='btn btn-secondary' disabled={this.state.callingBackend} onClick={this.cancelEdit}>Cancel</button>                                        
                                    </div>
                                    :
                                    <>
                                        {m.approvingOfficerFirstname?<>
                                            <button className='btn btn-outline-primary btn-sm me-3' disabled={this.state.callingBackend} onClick={()=>this.editApprovingOfficer(m)}><Pen/></button>
                                            {m.approvingOfficerFirstname} {m.approvingOfficerLastname}
                                        </>:<>
                                            <button className='btn btn-outline-primary btn-sm me-3' disabled={this.state.callingBackend} onClick={()=>this.editApprovingOfficer(m)}><Pen/></button>
                                            <i className='text-muted'>Not assigned</i>
                                        </>}
                                    </>}


                                    
                                </td>
                                <td className='text-end'>
                                    <button disabled={this.state.callingBackend} onClick={()=>this.openSiteAuditForm(m)} className='btn btn-primary btn-sm'>Open</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

            {records && records.length==0 && <div className='bg-light text-center p-3'>
                No records found
            </div>}
        </>
    }

}

const initStateCommission = {
    callingBackend:false,
    sorting:'CREATED',
    sortingDesc : true
}
class AssignMeterCommission extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initStateCommission));
    }

    async componentDidMount() {

    }

    
    save = async () => {
        if(!this.state.editApprovingOfficerForm.approvingOfficer) {
            this.state.editApprovingOfficerForm.approvingOfficerError = "Please select the approving officer"
            this.forceUpdate();
            return;
        } else {
            this.state.editApprovingOfficerForm.approvingOfficerError = null;
        }

        uiHelpers.showProgressOnTop('Saving...');
        this.setState(Object.assign(this.state,{callingBackend:true}));
        await apiCaller.post(serviceUrls.meterCommission.assignApprovingOfficer,{
            id:this.state.editApprovingOfficerForm.id,
            approvingOfficer:this.state.editApprovingOfficerForm.approvingOfficer
        })
        .then((data)=>{
            if(data.succeed){
                this.setState(Object.assign(this.state,{callingBackend:false,editApprovingOfficerForm:null}));
                this.props.refreshPage();
            }
        })
    }

    openMeterCommission  = (meterCommission) => {

        switch(meterCommission.formStatus){
            case 'PENDING_SITE_REGO':
            case 'PENDING_REGO_APPROVAL':
                this.setState({redirectTo:`/compliance-process/${meterCommission.id}/site-registration`});return;
            default:
                this.setState({redirectTo:`/compliance-process/${meterCommission.id}/validation`});return;
        }
    }

    convertFormStatus = (val) =>{
        if(val=='PENDING_REGO_APPROVAL') return "Registration QA"
        else if(val=='PENDING_VALIDATION_APPROVAL') return "Validation QA"
        return convertMeterCommissionStatus(val)
    }

    changeSorting = (sorting, sortingDesc) =>{
        this.setState({sorting,sortingDesc})
    }

    editApprovingOfficer = (meterCommission) => {
        this.state.editApprovingOfficerForm = {
            id : meterCommission.id,
            approvingOfficer: null,
            approvingOfficerError : null
        }
        this.forceUpdate();
    }

    cancelEdit = () => {
        this.state.editApprovingOfficerForm =null;
        this.forceUpdate();
    }

    onToggleAwaitingSolutionClick = async (id) => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.setState(Object.assign(this.state,{callingBackend:true}));
        await apiCaller.post(serviceUrls.meterCommission.adminToggleAwaitingResolution,{
            id:id
        })
        .then((data)=>{
            if(data.succeed){
                this.setState(Object.assign(this.state,{callingBackend:false,editApprovingOfficerForm:null}));
                this.props.refreshPage();
            }
        })
    }

    render() {
        const records = this.props.records;
        const approvingOfficers = this.props.approvingOfficers;
        
        return <>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo}  />}
            <div class="table-responsive-xxl">
                <table class="table table-striped" style={{"font-size":"0.8em"}}>
                    <thead>
                        <tr>
                            
                            <th scope="col" style={{width:"90px"}}>
                                Created 
                                {this.state.sorting=="CREATED" && !this.state.sortingDesc && <span onClick={()=>this.changeSorting('CREATED',true)} className='cursor-pointer text-primary'><SortUp /></span>}
                                {this.state.sorting=="CREATED" && this.state.sortingDesc && <span onClick={()=>this.changeSorting('CREATED',false)} className='cursor-pointer text-primary'><SortDown /></span>}
                                {this.state.sorting!="CREATED" && <span className='cursor-pointer text-muted' onClick={()=>this.changeSorting('CREATED',false)}><SortUp /></span>}
                            </th>
                            <th scope="col" style={{width:"90px"}}>
                                Updated
                                {this.state.sorting=="UPDATED" && !this.state.sortingDesc && <span onClick={()=>this.changeSorting('UPDATED',true)} className='cursor-pointer text-primary'><SortUp /></span>}
                                {this.state.sorting=="UPDATED" && this.state.sortingDesc && <span onClick={()=>this.changeSorting('UPDATED',false)} className='cursor-pointer text-primary'><SortDown /></span>}
                                {this.state.sorting!="UPDATED" && <span className='cursor-pointer text-muted' onClick={()=>this.changeSorting('UPDATED',false)}><SortUp /></span>}
                            </th>
                            <th scope="col" style={{width:"60px"}}>
                                #ID 
                                {this.state.sorting=="ID" && !this.state.sortingDesc && <span onClick={()=>this.changeSorting('ID',true)} className='cursor-pointer text-primary'><SortUp /></span>}
                                {this.state.sorting=="ID" && this.state.sortingDesc && <span onClick={()=>this.changeSorting('ID',false)} className='cursor-pointer text-primary'><SortDown /></span>}
                                {this.state.sorting!="ID" && <span className='cursor-pointer text-muted' onClick={()=>this.changeSorting('ID',false)}><SortUp /></span>}
                            </th>
                            <th scope="col" style={{width:"200px"}}>
                                Approval-ESID-Meter No. 
                                {this.state.sorting=="APPROVAL" && !this.state.sortingDesc && <span onClick={()=>this.changeSorting('APPROVAL',true)} className='cursor-pointer text-primary'><SortUp /></span>}
                                {this.state.sorting=="APPROVAL" && this.state.sortingDesc && <span onClick={()=>this.changeSorting('APPROVAL',false)} className='cursor-pointer text-primary'><SortDown /></span>}
                                {this.state.sorting!="APPROVAL" && <span className='cursor-pointer text-muted' onClick={()=>this.changeSorting('APPROVAL',false)}><SortUp /></span>}
                            </th>
                            <th scope="col" style={{width:"170px"}}>
                                Installation Type 
                                {this.state.sorting=="INSTALLATION_TYPE" && !this.state.sortingDesc && <span onClick={()=>this.changeSorting('INSTALLATION_TYPE',true)} className='cursor-pointer text-primary'><SortUp /></span>}
                                {this.state.sorting=="INSTALLATION_TYPE" && this.state.sortingDesc && <span onClick={()=>this.changeSorting('INSTALLATION_TYPE',false)} className='cursor-pointer text-primary'><SortDown /></span>}
                                {this.state.sorting!="INSTALLATION_TYPE" && <span className='cursor-pointer text-muted' onClick={()=>this.changeSorting('INSTALLATION_TYPE',false)}><SortUp /></span>}
                            </th>
                            <th scope="col" style={{width:"170px"}}>
                                Form Status
                                {this.state.sorting=="FORM_STATUS" && !this.state.sortingDesc && <span onClick={()=>this.changeSorting('FORM_STATUS',true)} className='cursor-pointer text-primary'><SortUp /></span>}
                                {this.state.sorting=="FORM_STATUS" && this.state.sortingDesc && <span onClick={()=>this.changeSorting('FORM_STATUS',false)} className='cursor-pointer text-primary'><SortDown /></span>}
                                {this.state.sorting!="FORM_STATUS" && <span className='cursor-pointer text-muted' onClick={()=>this.changeSorting('FORM_STATUS',false)}><SortUp /></span>}
                            </th>
                            <th scope="col" style={{width:"130px"}}>
                                DQP
                                {this.state.sorting=="DQP" && !this.state.sortingDesc && <span onClick={()=>this.changeSorting('DQP',true)} className='cursor-pointer text-primary'><SortUp /></span>}
                                {this.state.sorting=="DQP" && this.state.sortingDesc && <span onClick={()=>this.changeSorting('DQP',false)} className='cursor-pointer text-primary'><SortDown /></span>}
                                {this.state.sorting!="DQP" && <span className='cursor-pointer text-muted' onClick={()=>this.changeSorting('DQP',false)}><SortUp /></span>}
                            </th>
                            
                            <th scope="col">
                                Approving Officer
                                {this.state.sorting=="OFFICER" && !this.state.sortingDesc && <span onClick={()=>this.changeSorting('OFFICER',true)} className='cursor-pointer text-primary'><SortUp /></span>}
                                {this.state.sorting=="OFFICER" && this.state.sortingDesc && <span onClick={()=>this.changeSorting('OFFICER',false)} className='cursor-pointer text-primary'><SortDown /></span>}
                                {this.state.sorting!="OFFICER" && <span className='cursor-pointer text-muted' onClick={()=>this.changeSorting('OFFICER',false)}><SortUp /></span>}
                            </th>
                            <th scope="col" style={{width:"160px"}}></th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {records.sort((a,b)=>{
                            switch(this.state.sorting){
                                case 'CREATED' :
                                    if(this.state.sortingDesc) return (new Date(b.createdDate)).getTime() - (new Date(a.createdDate)).getTime();
                                    return (new Date(a.createdDate)).getTime() - (new Date(b.createdDate)).getTime();
                                case 'UPDATED' :
                                    if(!a.modifiedDate && !b.modifiedDate) return 0;
                                    if(this.state.sortingDesc) {
                                        if(!a.modifiedDate) return -1;
                                        if(!b.modifiedDate) return 1;
                                        return (new Date(a.modifiedDate)).getTime() - (new Date(b.modifiedDate)).getTime();
                                    }
                                    if(!a.modifiedDate) return 1;
                                    if(!b.modifiedDate) return -1;
                                    return (new Date(b.modifiedDate)).getTime() - (new Date(a.modifiedDate)).getTime();
                                case 'ID' :
                                    if(this.state.sortingDesc) return b.id - a.id;
                                    return a.id - b.id;
                                case 'INSTALLATION_TYPE' :
                                    if(this.state.sortingDesc) return b.installationType.localeCompare(a.installationType);
                                    return a.installationType.localeCompare(b.installationType);
                                case 'FORM_STATUS' :
                                    if(this.state.sortingDesc) return b.formStatus.localeCompare(a.formStatus);
                                    return a.formStatus.localeCompare(b.formStatus);
                                case 'DQP' :
                                    if(this.state.sortingDesc) return `${b.dqpFirstname} ${b.dqpLastname}`.localeCompare(`${a.dqpFirstname} ${a.dqpLastname}`);
                                    return `${a.dqpFirstname} ${a.dqpLastname}`.localeCompare(`${b.dqpFirstname} ${b.dqpLastname}`);
                                case 'OFFICER' :
                                    if(this.state.sortingDesc) return `${b.approvingOfficerFirstname} ${b.approvingOfficerLastname}`.localeCompare(`${a.approvingOfficerFirstname} ${a.approvingOfficerLastname}`);
                                    return `${a.approvingOfficerFirstname} ${a.approvingOfficerLastname}`.localeCompare(`${b.approvingOfficerFirstname} ${b.approvingOfficerLastname}`);
                                case 'APPROVAL':
                                    if(this.state.sortingDesc) return `${b.approval}-${b.esid}-${b.meterNumber}`.localeCompare(`${a.approval}-${a.esid}-${a.meterNumber}`);
                                    return `${a.approval}-${a.esid}-${a.meterNumber}`.localeCompare(`${b.approval}-${b.esid}-${b.meterNumber}`);
                                default: return 0;
                            }
                        }).map(m=>{

                            return <tr scope="row" className={this.state.editApprovingOfficerForm && this.state.editApprovingOfficerForm.id != m.id ? "opacity-25" : ""}>
                                
                                <td>{moment((new Date(m.createdDate))).format('DD/MM/YYYY')}</td>
                                <td>{m.modifiedDate?moment((new Date(m.modifiedDate))).format('DD/MM/YYYY'):""}</td>
                                <td>{m.id}</td>
                                <td>
                                    {m.approval}{m.esid?`-${m.esid}`:''}{m.meterNumber?`-${m.meterNumber}`:''}
                                </td>

                                {/* 
                                <td>{m.approval}</td>
                                <td>{m.esid}</td>
                                <td>{m.meterNumber}</td>
                                */}
                                <td>{m.installationType}</td>
                                <td>
                                    {this.convertFormStatus(m.formStatus)}
                                    {m.awaitingResolution && <><br/><span className='text-danger fs-80'>Awaiting internal resolution</span></>}
                                </td>
                                
                                <td>{m.dqpFirstname} {m.dqpLastname}</td>
                                <td>
                                    {this.state.editApprovingOfficerForm && this.state.editApprovingOfficerForm.id == m.id ? <div>
                                        <div className='mb-2'>
                                            <Select
                                                isDisabled={this.state.callingBackend}
                                                className={this.state.editApprovingOfficerForm.approvingOfficerError ? "react-select-invalid" : ""}
                                                getOptionLabel={(u)=><>{u.firstname} {u.lastname} <span className='text-muted fs-80'>({u.role=='CDO'?'CDO':'Super Admin'})</span></>}
                                                getOptionValue={(u)=>u.id}
                                                options={approvingOfficers}
                                                onChange={(u)=>{this.state.editApprovingOfficerForm.approvingOfficer = u; this.forceUpdate()}}
                                                value={this.state.editApprovingOfficerForm.approvingOfficer}
                                            />
                                            {this.state.editApprovingOfficerForm.approvingOfficerError && <div className='invalid-feedback'>{this.state.editApprovingOfficerForm.approvingOfficerError}</div>}
                                        </div>
                                        <button className='btn btn-primary me-2' disabled={this.state.callingBackend} onClick={this.save}>Save</button>
                                        <button className='btn btn-secondary' disabled={this.state.callingBackend} onClick={this.cancelEdit}>Cancel</button>                                        
                                    </div>
                                    :
                                    <>
                                        {m.approvingOfficerFirstname?<>
                                            <button className='btn btn-outline-primary btn-sm me-3' disabled={this.state.callingBackend} onClick={()=>this.editApprovingOfficer(m)}><Pen/></button>
                                            {m.approvingOfficerFirstname} {m.approvingOfficerLastname}
                                        </>:<>
                                            <button className='btn btn-outline-primary btn-sm me-3' disabled={this.state.callingBackend} onClick={()=>this.editApprovingOfficer(m)}><Pen/></button>
                                            <i className='text-muted'>Not assigned</i>
                                        </>}
                                    </>}


                                    
                                </td>
                                <td className='text-end'>
                                    {m.awaitingResolution ? 
                                    <button disabled={this.state.callingBackend} onClick={()=>this.onToggleAwaitingSolutionClick(m.id)} className='btn btn-outline-primary btn-sm me-1'><LockFill/></button>
                                    :
                                    <button disabled={this.state.callingBackend} onClick={()=>this.onToggleAwaitingSolutionClick(m.id)} className='btn btn-outline-secondary btn-sm me-1'><Lock/></button>
                                    }
                                    <button disabled={this.state.callingBackend} onClick={()=>this.openMeterCommission(m)} className='btn btn-primary btn-sm'>Open</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

            {records && records.length==0 && <div className='bg-light text-center p-3'>
                No records found
            </div>}
        </>
    }

}

const initState = {
    callingBackend:false,
    assigningFor:{key:'METER_COMPLANCE',name:'Meter Compliance Process'},
    keyword:'',
    formIdStr:'',
    formIdError:null,
    formId:null,
    dqp:null,
    approvingOfficer:null,
    unassignedOnly:false,
    meterCommissions:[],
    meterSiteAuditForms:[],
    approvingOfficers:[],
    sorting:'CREATED',
    sortingDesc : true
}

class AssignApprovingOfficer  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    async componentDidMount() {
        this.refreshPage();
    }

    search = (e) => {
        e.preventDefault();
        this.refreshPage();
    }

    refreshPage = async () => {
        let url;
        if(this.state.assigningFor && this.state.assigningFor.key == 'METER_COMPLANCE') url = serviceUrls.meterCommission.getMeterCommissionForAssigningOfficer;
        else if(this.state.assigningFor && this.state.assigningFor.key == 'SITE_AUDIT') url = serviceUrls.meterSiteAuditForm.getSiteAuditFormsForAssigningOfficer;
        else return;
        
        uiHelpers.showProgressOnTop('Please wait...');
        this.setState(Object.assign(this.state,{callingBackend:true}));
        

        await apiCaller.post(url,{
            keyword:this.state.keyword,
            formId:this.state.formId,
            unassignedOnly:this.state.unassignedOnly,
            dqpId:this.state.dqp?this.state.dqp.id:null,
            approvingOfficerId:this.state.approvingOfficer?this.state.approvingOfficer.id:null,
        })
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            newState.keyword = this.state.keyword;
            newState.formId = this.state.formId;
            newState.formIdStr = this.state.formIdStr;
            newState.formIdError = this.state.formIdError;
            newState.dqp = this.state.dqp;
            newState.approvingOfficer = this.state.approvingOfficer;
            if(this.state.assigningFor.key == 'METER_COMPLANCE') newState.meterCommissions = data.meterCommissions;
            if(this.state.assigningFor.key == 'SITE_AUDIT') newState.meterSiteAuditForms = data.meterSiteAuditForms;
            newState.approvingOfficers = data.approvingOfficers;
            newState.unassignedOnly = this.state.unassignedOnly;
            newState.sorting = this.state.sorting;
            newState.sortingDesc = this.state.sortingDesc;
            newState.assigningFor = this.state.assigningFor
            this.state = newState;
        }).finally(this.wholePageAfterRefresh);
    }

    wholePageAfterRefresh = () => {
        this.setState(Object.assign(this.state,{callingBackend:false}));
        uiHelpers.hideProgressOnTop();
    }

    

    onResetClick = () => {
        const newState = JSON.parse(JSON.stringify(initState));
        this.setState(newState,this.refreshPage);
    }

    onKeywordChanged = (e) => {
        this.setState({keyword:e.target.value});
    }

    onFormIdChange = (e) => {
        if(e.target.value.trim() == '' || isPositiveWholeNumber(e.target.value)) {
            this.setState({formIdStr:e.target.value, formIdError:null, formId:parseInt(e.target.value)})
        } else {
            this.setState({formIdStr:e.target.value, formIdError:'Not a valid ID', formId:null})
        }
    }

    onUnassignedCheckChange =(e) => {
        if(!this.state.unassignedOnly) {
            this.setState({
                unassignedOnly:!this.state.unassignedOnly,
                approvingOfficer:null
            })
        } else {
            this.setState({unassignedOnly:!this.state.unassignedOnly})
        }
    }

    onChangeAssigningForOption = (option) => {
        this.setState({assigningFor:option,meterCommissions:[]},this.refreshPage)
    }

    render() {
        return <>
            {this.state.redirectTo && <Redirect to={this.state.redirectTo}  />}
            <h3 class="mb-3">Assign Approving Officer</h3>

            <form action={""} onSubmit={this.search} class="mb-3">
                <div class="row mb-2">
                    <div class="col-md-5">
                        <label className='form-label'>Assign Officer For</label>
                        <Select
                            isDisabled={this.state.callingBackend}
                            getOptionLabel={(u)=><>{u.name}</>}
                            getOptionValue={(u)=>u.key}
                            options={[{key:'METER_COMPLANCE',name:'Meter Compliance Process'}, {key:'SITE_AUDIT',name:'Meter Site Audit Form'}]}
                            onChange={this.onChangeAssigningForOption}
                            value={this.state.assigningFor}
                        />
                    </div>
                    <div class="col-md-2">
                        <InputText
                            className="mb-3"
                            value={this.state.formIdStr} 
                            disabled={this.state.callingBackend}
                            onChange={this.onFormIdChange}
                            label={<>Form ID</>}
                            error={this.state.formIdError}
                        />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-7">
                        <InputText
                            className="mb-3"
                            value={this.state.keyword} 
                            disabled={this.state.callingBackend}
                            onChange={this.onKeywordChanged}
                            label={<>Keyword <i className='fs-80'>(Approval / ESID / Worksite ID)</i></>}
                        />
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-7">
                        <div class="accordion mb-3" id="accordionCriteries">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingCirterias">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCriterias" aria-controls="collapseFormTypes">
                                    Advanced Criteria
                                    </button>
                                </h2>
                                <div id="collapseCriterias" class="accordion-collapse collapse" aria-labelledby="headingCirterias" data-bs-parent="#accordionCriteries">
                                    <div class="accordion-body">
                                        <div className='mb-3'>
                                            <label className='form-label'>DQP / Auditor</label>
                                            <UsersDropDown onChange={(u)=>this.setState({dqp:u})} isDisabled={this.state.callingBackend} selectedUser={this.state.dqp} />
                                        </div>
                                        <div className='my-4'>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" id="unassignedSwitch" onChange={this.onUnassignedCheckChange} checked={this.state.unassignedOnly} />
                                                <label class="form-check-label" for="unassignedSwitch">Display unassigned record/s only</label>
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <label className='form-label'>Approving Officer</label>
                                            <Select
                                                isDisabled={this.state.callingBackend || this.state.unassignedOnly}
                                                getOptionLabel={(u)=><>{u.firstname} {u.lastname} <span className='text-muted fs-80'>({u.role=='CDO'?'CDO':'Super Admin'})</span></>}
                                                getOptionValue={(u)=>u.id}
                                                options={this.state.approvingOfficers}
                                                onChange={(u)=>this.setState({approvingOfficer:u})}
                                                value={this.state.approvingOfficer}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <button type="submit" class="btn btn-primary me-3" disabled={this.state.callingBackend}>Search</button>
                    <button type="button" class="btn btn-secondary" onClick={this.onResetClick} disabled={this.state.callingBackend}>Reset</button>
                </div>
            </form>

            {this.state.assigningFor.key == 'METER_COMPLANCE' && <AssignMeterCommission records={this.state.meterCommissions} refreshPage={this.refreshPage} approvingOfficers={this.state.approvingOfficers}/>}
            {this.state.assigningFor.key == 'SITE_AUDIT' && <AssignMeterSiteAudit records={this.state.meterSiteAuditForms} refreshPage={this.refreshPage} approvingOfficers={this.state.approvingOfficers}/>}
            
        </>
    }
}

export default AssignApprovingOfficer;

