import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import React from 'react';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import { reducer as formReducer } from 'redux-form';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import authentication from './helpers/reactazureb2c';
import { jwtAuthentication } from './helpers/jwtSignon';

import reducerMeterDesign from './components/certificates/design/MeterDesignCertificate.Reducers';
import reducerMeterValidation from './components/certificates/meterValidation/MeterValidationCertificate.Reducers'
import reducerMeterAccuracy from './components/certificates/accuracy/AccuracyExistingMeteringCertificate.Reducers';
import reducerTransitionalReporting from './components/certificates/transitionalReporting/TransitionalReporting.Reducers'
import reducerAHDValidation from './components/certificates/ahdValidation/AHDValidationCertificate.Reducers';
import usbReducer  from './components/certificates/updateRatingTable/Reducers';
import sevReducer from './components/certificates/secondaryEquipmentValidation/Reducers';
import smvReducer from './components/certificates/storageManagementValidation/Reducers';

import Register from './layout/Register';

import HowToGuides from './layout/HowToGuides';
import ValidateConsent from './layout/ValidateConsent';
import RequestConsent from './layout/RequestConsent';
import TrackingStatusFPH from './layout/TrackingStatusFPH';
import TrackingStatusNUM from './layout/TrackingStatusNUM';
import SearchApproval from './layout/admin/SearchApproval';
import SearchForms from './layout/admin/SearchForms';
import RatingTablesSearch from './layout/RatingTablesSearch';
import NonUrbanRegistrationForm from './layout/NonUrbanRegistrationForm';
import FPHRegistrationForm from './layout/FPHRegistrationForm';
import NUMTelemetryReplacement from './layout/NUMTelemetryReplacement';
import AHDDeclaration from './layout/AHDDeclaration';
import UpdateRatingTableCertificate from './layout/UpdateRatingTableCertificate';
import SecondaryEquipmentValidationCertificate from './layout/SecondaryEquipmentValidationCertificate';
import StorageManagementValidationCertificate from './layout/StorageManagementValidationCertificate';
import FPHValidationSelectMeasurementMethod from './layout/FPHValidationSelectMeasurementMethod';
import TransitionalReportingForm from './layout/TransitionalReporting';
import MeterValidationCertificateForm from './layout/MeterValidationCertificate';
import AHDValidationCertificate from './layout/AHDValidationCertificate';
import MeterDesignCertificateForm from './layout/MeterDesignCertificate';
import AccuracyExistingMeteringForm from './layout/AccuracyExistingMeteringCertificate';
import MeterValidationCopy from './layout/MeterValidationCopy';
import FormsWithIssues from './layout/admin/FormsWithIssues';
import ManageUsers from './layout/admin/ManageUsers';
import ReferenceData from './layout/admin/ReferenceData';
import PageNotFound from './layout/errors/PageNotFound'
import NominatedMeasurementPeriodRoutes from './nominatedMeasurementPeriod/Routes'
import Help from './modules/others/components/Help';

import axios from 'axios';
import './config';
import './App';
import './constants/Constants';
import ReactGA from "react-ga";
import { Home2 as ReportingPortal } from './fphMeasurement/Home2';

import HashRoutes from './modules/Routes'



import BasePageLayout, {menuItems, MenuConnector, SetCurrentMenu, AuthenticationRequired, AuthenticationSwitch} from './layout/base/BasePageLayout'
import LidInstallationVendor from './modules/meterCommisioningProcess/components/LidInstallationVendor';
import ContactForm from './modules/others/components/ContactForm';

axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';
var reducers = {
    smvReducer,
	  sevReducer,
    usbReducer,
    reducerMeterDesign,
    reducerMeterValidation,
    reducerTransitionalReporting,
    reducerMeterAccuracy,
    reducerAHDValidation,
    form: formReducer
}
jwtAuthentication.initialize();
var action = localStorage.getItem("b2caction")
var actionRedirect = localStorage.getItem("b2credirect");
if(action != undefined && action != null && action != "")
{
  
  if(action == "SIGNUP")
  {
    authentication.signUp();
  }
  if(actionRedirect != undefined && actionRedirect != null && actionRedirect != "")
  {
    localStorage.removeItem("b2caction");
    localStorage.removeItem("b2credirect");
  }
  //localStorage.removeItem("b2caction");
}

var reducer = combineReducers(reducers);
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;
//ReactDOM.render(<App/>, document.getElementById('root'));
const enhancer = composeEnhancers(
    applyMiddleware(thunk),
    // other store enhancers if any
  );
const store = createStore(reducer, enhancer);
const history = createBrowserHistory();
ReactGA.initialize(process.env.REACT_APP_GTAG_ID);

const menuConnector = new MenuConnector();



ReactDOM.render(
  <Provider store={store}>
    <Router history={history} forceRefresh={true}>

        <Switch>
          
          <Route path="/ReportingPortal" component={jwtAuthentication.required(ReportingPortal)} />  

          <Route path="/measurement-period/*" component={NominatedMeasurementPeriodRoutes} />  

          {/* 
          <Route path="/num-mockups/*" component={NumProcessMockupsRoutes} />  
          */}


          <Route exact path="/consent" >
            <BasePageLayout noMenuBar={true}>
              <ValidateConsent/>
            </BasePageLayout>
          </Route>

          <Route exact path="/vendor-lid-installation/:guid" >
            <BasePageLayout noMenuBar={true}>
              <LidInstallationVendor/>
            </BasePageLayout>
          </Route>
          
          <Route exact path="*">
            <BasePageLayout menuConnector={menuConnector}>
              <Switch>



                {/*
                <Route path="/meter-commisioning/*">
                  <AuthenticationRequired>
                    <SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.nonUrbanMetering()}>
                      <MeterCommisioningProcessRoutes/>  
                    </SetCurrentMenu>
                  </AuthenticationRequired>
                </Route>  
                */}

                


                <Route exact path="/">
                  <AuthenticationSwitch
                    renderIfAuthenticated={<HashRoutes menuConnector={menuConnector}/>}
                    renderIfNotAuthenticated={<SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.register()}><Register/></SetCurrentMenu>}
                  />
                </Route>

                <Route exact path="/Home" >
                  <AuthenticationRequired>
                    <Redirect to='/#/home' />
                  </AuthenticationRequired>
                </Route>
                
                
                <Route exact path="/Help" >
                  <SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.help()}>
                    <Help/>
                  </SetCurrentMenu>
                </Route>

                <Route exact path="/contact-form" >
                  <SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.help()}>
                    <ContactForm/>
                  </SetCurrentMenu>
                </Route>

                <Route exact path="/how-to-guide" >
                  <SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.help()}>
                    <HowToGuides/>
                  </SetCurrentMenu>
                </Route>

                {/* 
                <Route exact path={"/Home"}>
                  <AuthenticationRequired>
                    <SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.home()}><Home/></SetCurrentMenu>
                  </AuthenticationRequired>
                </Route>
                */}

                <Route exact path="/request-consent" >
                  <AuthenticationRequired>
                    <SetCurrentMenu menuConnector={menuConnector} currentMenu={null}><RequestConsent/></SetCurrentMenu>
                  </AuthenticationRequired>
                </Route>
                <Route path={[
                  "/NonUrbanRegistration",
                  "/NonUrbanTelemetryReplacement",
                  "/LandHolderTrackingStatusNUM",

                  
                  "/TransitionalReporting",
                  "/MeterValidationCopy",
                  "/MeterDesignCertificate",
                  "/accuracy-certificate-num",
                  "/validation-certificate-num",
                ]}>
                  <AuthenticationRequired>
                    <SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.nonUrbanMetering()}>
                      <Route exact path="/NonUrbanRegistration" component={NonUrbanRegistrationForm} />
                      <Route exact path="/NonUrbanTelemetryReplacement" component={NUMTelemetryReplacement} />
                      <Route  exact path="/LandHolderTrackingStatusNUM" component={TrackingStatusNUM} /> 
                      <Route exact path="/TransitionalReporting"><TransitionalReportingForm form="transitionalReporting"/></Route>
                      <Route exact path="/MeterValidationCopy"><MeterValidationCopy form="meterValidationCopy"/></Route>
                      <Route exact path="/MeterDesignCertificate"><MeterDesignCertificateForm form="meterDesign"/></Route>
                      <Route exact path="/accuracy-certificate-num"><AccuracyExistingMeteringForm form="meterAccuracy" /></Route>
                      <Route exact path="/validation-certificate-num"><MeterValidationCertificateForm form="meterValidation"/></Route>
                    </SetCurrentMenu>
                  </AuthenticationRequired>
                </Route>
                <Route path={[
                  "/RatingTablesSearch",
                  "/FPHRegistration",
                  "/LandHolderTrackingStatusFPH",

                  "/AHDDeclaration",
                  "/SurveyBenchmarkCertificate",
                  "/UpdateRatingTable",
                  "/SecondaryEquipmentValidation",
                  "/StorageManagementValidation",
                  "/fph-validation-select-measurement-method",
                  "/accuracy-certificate-fph",
                  "/validation-certificate-point-of-intake",
                ]}>
                  <AuthenticationRequired>
                    <SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.floodplainHarvesting()}>
                      <Route exact path="/RatingTablesSearch" component={RatingTablesSearch} />
                      <Route exact path="/FPHRegistration" component={FPHRegistrationForm} />
                      <Route  exact path="/LandHolderTrackingStatusFPH" component={TrackingStatusFPH} />
                      <Route exact path="/AHDDeclaration" component={AHDDeclaration} />       
                      <Route exact path="/SurveyBenchmarkCertificate"><AHDValidationCertificate form="ahdValidation"/></Route> 
                      <Route  path="/UpdateRatingTable"><UpdateRatingTableCertificate form="updateRatingTable"/></Route>    
                      <Route  exact path="/SecondaryEquipmentValidation"><SecondaryEquipmentValidationCertificate form="secondaryEquipmentValidation"/></Route>
                      <Route  exact path="/StorageManagementValidation"><StorageManagementValidationCertificate form="storageManagementValidation"/></Route>  
                      <Route path="/fph-validation-select-measurement-method" component={FPHValidationSelectMeasurementMethod}/> 
                      <Route exact path="/accuracy-certificate-fph"><AccuracyExistingMeteringForm form="meterAccuracy" fphMode={true} /></Route>
                      <Route exact path="/validation-certificate-point-of-intake"><MeterValidationCertificateForm form="meterValidation" fphMode={true} /></Route>
                    </SetCurrentMenu>
                  </AuthenticationRequired>
                </Route>
                <Route exact path={[
                  "/admin-search-approvals",
                  "/admin-forms",
                  "/admin-forms-with-issues",
                  "/admin-manage-users",
                  "/admin-reference-data"
                ]}>
                  <AuthenticationRequired>
                    <SetCurrentMenu menuConnector={menuConnector} currentMenu={menuItems.admin()}>
                      <Route path="/admin-search-approvals" component={SearchApproval}/> 
                      <Route path="/admin-forms" component={SearchForms}/>  
                      <Route path="/admin-forms-with-issues" component={FormsWithIssues}/>  
                      <Route path="/admin-manage-users" component={ManageUsers}/>  
                      <Route path="/admin-reference-data" component={ReferenceData}/>  
                    </SetCurrentMenu>
                  </AuthenticationRequired>
                </Route>
                <Route path="*">
                  <PageNotFound/>
                </Route>
              </Switch>
            </BasePageLayout>
          </Route>
        </Switch>
    </Router>
  </Provider>, 
  document.getElementById('root'),
  document.getElementById('fileupload'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



