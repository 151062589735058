import React from 'react';
import { AsteriskRed } from '../../../../components/tools/Others';

export default class ValidationCustomerSiteFields  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        const meterCommission = this.props.meterCommission;
        if(meterCommission.formVersion==2) return <>
            {form.renderUneditableTextInputRow('customersSiteName',<>Customer Site Name</>,{rowClasses:'mb-3'})}   
            {form.renderUneditableTextInputRow('customersPumpNumber',<>Customer Pump Number</>,{})}   
        </>;
        return <>
            {form.renderTextBoxRow('customersSiteName',<>Customer Site Name</>,{rowClasses:'mb-3'})}
            {form.renderTextBoxRow('customersPumpNumber',<>Customer Pump Number</>,{})}
        </>
    }
}