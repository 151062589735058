import e from "cors";
import { FlatForm,  mandatoryValidator, positiveWholeNumberValidator, preConditionToValidate, trueValidator, txtMandatoryValidator } from "../../../helpers/FormHelper";
import { isStringNullOrEmpty } from "../../../helpers/validators";

export const getMeterSiteAuditApprovalForm = (formName, context, rejectAction, approveAction) => {

    const result = new FlatForm(formName, context);
    result.initialise([
        "id",
        "status",
        "createdBy",
        "createdDate",
        "approvingOfficer",
        "comments"
    ])
    .defineAction('reject',rejectAction,{
        comments:[txtMandatoryValidator("This is required")]
    })
    .defineAction('approve',approveAction,{
        comments:[txtMandatoryValidator("This is required")]
    })
    return result;
}