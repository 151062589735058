import React from 'react';
import axios from 'axios';
import $ from 'jquery'
import * as bootstrap from 'bootstrap';
//import Authentication from '../../helpers/Authentication'
import {InputTextWithFloatingLabel} from '../tools/BootstrapControls'
import uiHelpers from '../../helpers/uiHelpers';
import * as apiCaller from '../../helpers/apiCaller'
//const authentication = new Authentication();

class FileUploader extends React.Component {

    #modalElementId = "model_" + (new Date()).getTime() + Math.floor(Math.random() * 1000);
    #fileElementId = "file_" + (new Date()).getTime() + Math.floor(Math.random() * 1000);
    #modal;
    #fileGUID;
    constructor(props) {
        super(props);
        this.state = {
            filesForUpload : null,
            fileError : null,
            loading : false,
            documents :[]
        }
        if(props.provideDisplayDocumentsUpdator)
            props.provideDisplayDocumentsUpdator(this.updateDisplayFileList);
    }

    componentDidMount(){
        this.#modal = new bootstrap.Modal(document.getElementById(this.#modalElementId));
        document.getElementById(this.#modalElementId).addEventListener('hidden.bs.modal', this.reset);
        if(this.props.askForDocumentUpdate)
            this.props.askForDocumentUpdate();
    }

    updateDisplayFileList = (documents) =>{
        if(documents && documents.length>0){
            this.#fileGUID = documents[0].fileGUID;
        }
        this.setState(Object.assign(this.state,{documents} ));
    }

    reset =()=>{
        $('#' + this.#fileElementId).val('');
        this.setState(Object.assign(this.state,{filesForUpload : null} ));
    }

    confirm = () => {

        let formData = new FormData();
        let files = document.getElementById(this.#fileElementId).files;
        for (var i = 0; i < files.length; ++i) {

            formData.append("files", files[i]);
            formData.append("description", this.state.filesForUpload[i].description);
        }

        if (this.#fileGUID) {
            formData.append("fileguid", this.#fileGUID);
        }

        /*
        const config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + authentication.getToken()
            }
        };
        */

        var currentContext = this;
        currentContext.setState(Object.assign(currentContext.state, { loading: true }));
        if(currentContext.props.onStartUploading)
            currentContext.props.onStartUploading()

        /*
        axios.post(process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FILES_UPLOADMULTIPLE, formData, config)
        .then(function (response) {
            if(currentContext.props.onFilesUploaded)
                currentContext.props.onFilesUploaded(response.data.payload.files);
        }).catch((error)=>{
            console.log(error);
            uiHelpers.showErrorTop("Failed to upload files...");
        }).then(()=>{
            currentContext.setState(Object.assign(currentContext.state, { loading: false }));
            currentContext.#modal.hide();
        });
        */

        apiCaller.post(process.env.REACT_APP_FILES_UPLOADMULTIPLE,formData)
        .then((data)=>{
            if(currentContext.props.onFilesUploaded)
                currentContext.props.onFilesUploaded(data.payload.files);
        }).catch((error)=>{
            console.log(error);
            uiHelpers.showErrorTop("Failed to upload files...");
        }).finally(()=>{
            currentContext.setState(Object.assign(currentContext.state, { loading: false }));
            currentContext.#modal.hide();
        });
        
    }

    onFileChange = (e) => {
        
        this.setState(Object.assign(this.state,{fileError : null} ));
        var filesForUpload = [];
        for (var i = 0; i < e.target.files.length; ++i) {
            if(this.props.allowFileTypes) {
                if(!this.props.allowFileTypes.includes(e.target.files[i].type)) {
                    this.setState(Object.assign(this.state,{fileError : this.props.fileTypeErrorMsg ? this.props.fileTypeErrorMsg : 'Some file/s type is not allowed'} ));
                    return;
                }
            }
            if(this.props.maxFileSizeMB){
                if(this.props.maxFileSizeMB < (e.target.files[i].size/(1024*1024))){
                    this.setState(Object.assign(this.state,{fileError : this.props.exceedFileSizeErrorMsg ? this.props.exceedFileSizeErrorMsg : 'Some file/s are too big'} ));
                    return;
                }
            }

            filesForUpload.push({
                index : i,
                fileName : e.target.files[i].name,
                description : ""
            });
        }

        this.setState(Object.assign(this.state,{filesForUpload} ), ()=>{
            this.#modal.show();
        });
    }

    deleteFile =(file) =>{
        if(this.props.onFileDeleted)
            this.props.onFileDeleted(file);
    }

    render() {
        return <>
            {!this.props.notShowInput && (!this.props.singleFileMode || (this.props.singleFileMode && this.state.documents.length==0)) &&
            <div class="mb-3">
                {this.props.fileInputLabel && <label for="formFile" class="form-label">{this.props.fileInputLabel}</label>}
                <input id={this.#fileElementId} disabled={this.state.loading || this.props.disabled} multiple={this.props.singleFileMode?false:true} className={`form-control ${this.state.fileError && this.state.fileError!='' ? 'is-invalid' : ''}`} type="file" onChange={this.onFileChange} />
                <div class="invalid-feedback">{this.state.fileError}</div>
                {this.props.warning && <div class="form-text text-warning">{this.props.warning}</div>}
            </div>}

            {this.state.documents && this.state.documents.length > 0 && <>
                {this.props.fileTableHeader}
                <table class="table table-sm align-middle">
                    <thead>
                        {!this.props.fileTableHeadComponent && <tr>
                            <th style={{width:"80px"}}></th>
                            <th>File name</th>
                            <th className='w-50'>Description</th>
                        </tr>}
                        {this.props.fileTableHeadComponent && this.props.fileTableHeadComponent }
                    </thead>
                    <tbody>
                        {this.state.documents.map((file, index) => {
                            if(this.props.fileTableRowCompponent){
                                return this.props.fileTableRowCompponent(file, index, <button disabled={this.state.loading || this.props.disabled} type="button" class="btn btn-outline-secondary btn-sm" onClick={()=>{this.deleteFile(file)}}>Delete</button>);
                            }
                            return <tr key={index}>
                                <td>
                                    <button disabled={this.state.loading || this.props.disabled} type="button" class="btn btn-outline-secondary btn-sm" onClick={()=>{this.deleteFile(file)}}>Delete</button>
                                </td>
                                <td>
                                    <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                                        {file.fileOriginalName}
                                    </a>
                                </td>
                                <td>{file.description}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </>}


            <div class="modal fade" id={this.#modalElementId} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Upload files</h5>
                        </div>
                    <div class="modal-body">
                        {this.state.filesForUpload && <>
                            
                            <ul class="list-group">
                                
                                {this.state.filesForUpload.map((file, index) => {
                                    return <li class="list-group-item" key={index}>
                                        <p><strong>{file.fileName}</strong></p>
                                        <div>
                                            <InputTextWithFloatingLabel 
                                                label="Description: "
                                                value={file.description}
                                                className="mb-3"
                                                disabled={this.state.loading || this.props.disabled}
                                                onChange={(e)=>{
                                                    file.description = e.target.value;
                                                    this.setState({...this.state});
                                                }}
                                            />
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </>}
                    </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" disabled={this.state.loading || this.props.disabled} data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.loading || this.props.disabled} onClick={this.confirm}>
                                Confirm
                                {this.state.loading && <>&nbsp;&nbsp;&nbsp;<div class="spinner-grow spinner-grow-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div></>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    }

}

export default FileUploader;

