import React from 'react';
import { Redirect } from 'react-router-dom';
import {Dot, ArrowRight} from '../../../components/tools/Icons'
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import { InputTextWithFloatingLabel } from '../../../components/tools/BootstrapControls';
import { convertMeterStatus, nullToEmpty } from '../../../helpers/converters';
import moment from 'moment';

const initState = {
    callingService:false,
    keyword:'',
    searchResult:null,
    selectedMeter:null
};

class SiteAuditStart  extends React.Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({}, initState);
    }

    onKeywordTextBoxChanged = async (e) => {
        this.setState({keyword : nullToEmpty(e.target.value).trim().toUpperCase()})
    }

    onSearchBtnClick = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.setState({callingService:true})
        await apiCaller.post(serviceUrls.admin.searchMeter,{keyword:this.state.keyword})
        .then((data)=>{
            this.setState({
                searchResult:data.result,
                callingService:false
            })
        })
        .finally(()=>{
            uiHelpers.hideProgressOnTop();
        });
    }

    onSelectMeterBtnClick = (meter) => {
        this.setState({selectedMeter:meter});
    }

    onCancelClick = () => {
        this.setState({selectedMeter:null});
    }

    onConfirmClick = async () => {
        uiHelpers.showProgressOnTop('Creating meter compliance process...');
        this.setState(Object.assign(this.state,{callingService:true}));
        await apiCaller.post(serviceUrls.meterSiteAuditForm.createMeterSiteAuditForm,{meterId:this.state.selectedMeter.id})
        .then((result)=>{
            this.setState({redirectTo:`/site-audit/${result.meterSiteAuditFormId}/form`})
        }).finally(()=>{
            this.setState(Object.assign(this.state,{callingService:false}));
            uiHelpers.hideProgressOnTop();
        });
    }

    renderSerarchArea() {
        return <>
            <div class="row mb-2">
                <div class="col-md-6">
                    <InputTextWithFloatingLabel
                        className="mb-3"
                        value={this.state.keyword}
                        disabled={this.state.callingService}
                        onChange={this.onKeywordTextBoxChanged}
                        label={<>Keyword <i className='fs-80'>(Approval / ESID / Worksite ID)</i></>}
                    />
                    <p>
                        <button type="button" class="btn btn-primary" disabled={this.state.callingService} onClick={this.onSearchBtnClick}>
                            Search
                        </button>
                    </p>
                </div>
            </div>


            {this.state.searchResult && this.state.searchResult.length == 0 && <>
                <div className='alert alert-secondary'>
                    No record found
                </div>
            </>}

            {this.state.searchResult && this.state.searchResult.length > 0  && <>
                <ul className={`list-group ${this.state.searching ? 'opacity-25':''}`}>
                    {this.state.searchResult.map(m=><li class="list-group-item">
                        <div className='row'>
                            <div className='col-md-4 mb-3'>
                                {m.meterSiteId.meterSiteId}
                                <br/><span class="fs-80 text-secondary">ID: #<b>{m.id}</b></span>
                                <br/><span class="fs-80 text-secondary">Created: <b>{moment(m.createdDate).format('DD/MM/YYYY')}</b></span>
                            </div>
                            <div className='col-md-6 mb-3'>
                                <span class="fs-80 text-secondary">Status: <b className='text-primary'>{convertMeterStatus(m.status)}</b></span>
                                <br/><span class="fs-80 text-secondary">LID: <b>{m.meterLid.lid.displayText}</b></span>
                                <br/><span class="fs-80 text-secondary">Manufacturer and Model: <b>{m.meterTypeModel.name}</b></span>
                            </div>
                            <div className='col-md-2 text-end'>
                                <button className='btn btn-primary' onClick={()=>this.onSelectMeterBtnClick(m)} disabled={this.state.callingService}>Start Audit Form</button>
                            </div>
                        </div>
                    </li>)}
                </ul>
            </>}
        </>
    }

    renderConfirmationArea() {
        return   <>
            <div className='card border-primary border-1'>
                <div className='card-body bg-primary bg-opacity-10 text-center'>
                    <p className='text-primary'>
                        Please confirm that you would like to start a Meter Site Audit Form for this meter
                    </p>
                    <p className='text-primary'> 
                        <b>{this.state.selectedMeter.meterSiteId.meterSiteId} <i className='fs-80'>({convertMeterStatus(this.state.selectedMeter.status)})</i></b>
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.onCancelClick} disabled={this.state.callingService}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.onConfirmClick} disabled={this.state.callingService}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div> 
        </>
    }

    render() {
        if(this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;
        return <>
            <h3 class="mb-3">Meter Site Audit</h3>
            <div className='card mb-4'>
                <div className='card-body py-1 fs-90'>
                    <span className='text-primary'><Dot/></span><strong className='text-primary'>Find a Meter Site</strong> 
                    <span className='px-2'><ArrowRight/></span>
                    <i className='text-secondary fs-80'>Site Audit Form</i>
                </div>
            </div>

            {this.state.selectedMeter ? this.renderConfirmationArea() : this.renderSerarchArea()}

            
        </>
    }
}

export default SiteAuditStart;