import React, { useState , useEffect} from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import * as bootstrap from 'bootstrap'
import moment from 'moment';
import {PDF, Download} from '../../tools/Icons'
import {InputText} from '../../tools/BootstrapControls'


let CertificateFiles = props => {

    let {documents, certificateEntity , fileTypeResolver} = props

    const [view, setView] = useState('LIST');
    const [canvasImage, setCanvasImage] = useState('');


    var bsOffcanvas;
    useEffect(() => {
        bsOffcanvas = new bootstrap.Offcanvas(document.getElementById('offcanvasBottom'));
    });


    const imageDocuments = [];
    const nonImageDocuments = [];
    
    documents.map((document)=>{
        if(document.fileOriginalName.toUpperCase().includes('.PNG') || document.fileOriginalName.toUpperCase().includes('.GIF') || document.fileOriginalName.toUpperCase().includes('.JPG') || document.fileOriginalName.toUpperCase().includes('.JPEG')) {
            imageDocuments.push(document);
        } else {
            nonImageDocuments.push(document);
        }
    })

    return <>

        <h5 className='mb-3'>Certificate</h5>


            {certificateEntity.pdfGeneratedDate && <div className='row mb-3'>
                <div className='col-md-3'>
                    <InputText 
                        label="Generated at"
                        disabled={true}
                        value={moment((new Date(certificateEntity.pdfGeneratedDate))).format('DD/MM/YYYY h:mma')}
                    />
                </div>
            </div>}

            <div className='mb-5'>
                {certificateEntity.generatedCertificatePath!=null &&  
                <a className='btn btn-outline-primary' target="_blank" href={process.env.REACT_APP_SHARE_POINT_ROOT_URL + certificateEntity.generatedCertificatePath}>
                        <PDF/> Download
                </a>}

                {certificateEntity.generatedCertificatePath==null && 
                    <div className='alert alert-warning'>
                        We cannot find the generated certificate.
                    </div>
                }
            </div>

        

        

        <h5 className='mb-3'>Supporting documents</h5>

        <div class="btn-group mb-3" role="group">
            <button type="button" class={`btn ${view=='LIST' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={()=>setView('LIST')}>List View</button>
            <button type="button" class={`btn ${view=='IMAGE' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={()=>setView('IMAGE')}>
                Image View
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                    Show images Only
                </span>
            </button>
        </div>

        {view=='IMAGE' && <div>
            {imageDocuments.length==0&&<div className='alert alert-primary'>
                No image file available in the supporting document
            </div>}
            {imageDocuments.map((document) =>
                <img 
                    src={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + document.id} 
                    class="img-thumbnail rounded pointer-cursor m-2" 
                    style={{width:"200px",height:"200px", "object-fit": "cover"}} 
                    onClick={()=>{setCanvasImage(process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + document.id);bsOffcanvas.show();}}
                />
            )}
        </div>}

        {view=='LIST' && <>
            <table className='table table-sm fs-90'>
                <thead>
                    <tr>
                        <th style={{"width":"35px"}}></th>
                        {fileTypeResolver && <th style={{width:"30%"}}>File Type</th> }
                        <th style={{width:"30%"}}>File Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.length==0 && <tr className='table-primary'>
                        <td colSpan={5} className='text-center'>
                            No document available
                        </td>
                    </tr>}
                    {documents.map((document) =>
                        <tr>
                            <td>
                                <a target='_blank' className='btn btn-outline-secondary btn-sm btn-icon-16' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + document.id}><Download/></a>
                            </td>
                            {fileTypeResolver && <td>
                                {fileTypeResolver(document)}
                            </td>}
                            <td>
                                {document.fileOriginalName}
                            </td>
                            <td>
                            {document.description}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>}


        <div class="offcanvas offcanvas-bottom h-75" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
            <div class="offcanvas-body small text-center">
                <img src={canvasImage} />
            </div>
        </div>
        
    </>
 }
 
 CertificateFiles = reduxForm({
    destroyOnUnmount: false
 })(CertificateFiles)
 
 CertificateFiles = connect(
    (state,props) => {
       const selector = formValueSelector(props.form)
       const documents = selector(state, 'documents')
       //const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
       const certificateEntity = selector(state, props.certificateNameForSelector)
       return {
          documents,
          certificateEntity
       }
    })(CertificateFiles)
 
 export default CertificateFiles


