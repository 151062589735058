import { FlatForm, latitudeNswValidator , longitudeNswValidator, mandatoryValidator, positiveNumberValidator, positiveWholeNumberValidator, preConditionToValidate, txtMandatoryValidator} from "../../../helpers/FormHelper";
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import uiHelpers from '../../../helpers/uiHelpers';
import { toStringIfNotNull } from "../../../helpers/formatters";
import { replacingLidReasonsRequireRef } from "../../../constants/MeterFormConstants";



export const getMeterSiteAuditForm = (formName, context, saveFormAction,submitFormAction) => {

    const result = new FlatForm(formName, context);

    const changeAndSaveValidators = {
        upstreamPipeLength:[positiveNumberValidator('Please enter a valid number')],
        downstreamPipeLength:[positiveNumberValidator('Please enter a valid number')],
        inspectionMeterRead:[positiveNumberValidator('Please enter a valid number')],
        inspectionDASRawFlow:[positiveNumberValidator('Please enter a valid number')],
    };

    result.initialise([
        "id",
        "status",
        "createdBy",
        "createdDate",
        "owner",
        "approval",
        "extractionSite",
        "works",
        "holders",
        "meter",
        "meetAS4747",
        "detailsNoAS4747",
        "elecIntegrityChecks",
        "detailsNoElecIntegrityChecks",
        "powerSupplyInSpec",
        "detailsNoPowerSupplyInSpec",
        "solarChargingInSpec",
        "detailsSolarChargingInSpec",
        "upstreamPipeLength",
        "downstreamPipeLength",
        "lidSerialNumber",
        "lidConfigurationUnit",
        "scallingFactor",
        "inspectionCompletedDate",
        "inspectionMeterRead",
        "inspectionDASRawFlow",
        "inspectionDASAlarms",
        "inspectionDASAlarmsYes",
        "instructions",

        "originalTamperSealNumbers",
        "newTamperSealNumbers",

        "tamperPhotoDoc",
        "meterEmplacementDoc",
        "meterReadingDoc",
        "documents",
        "certificatePath"
    ])
    .setValueChangedValidators(changeAndSaveValidators)
    .setPostChangeActions({
        meetAS4747 : (val,vals,form)=>{
            form.vals({detailsNoAS4747:''}, false);
            form.errs({detailsNoAS4747:null});
        },
        elecIntegrityChecks : (val,vals,form)=>{
            form.vals({detailsNoElecIntegrityChecks:''}, false);
            form.errs({detailsNoElecIntegrityChecks:null});
        },
        powerSupplyInSpec : (val,vals,form)=>{
            form.vals({detailsNoPowerSupplyInSpec:''}, false);
            form.errs({detailsNoPowerSupplyInSpec:null});
        },
        solarChargingInSpec : (val,vals,form)=>{
            form.vals({setailsSolarChargingInSpec:''}, false);
            form.errs({setailsSolarChargingInSpec:null});
        },
        inspectionDASAlarms : (val,vals,form)=>{
            form.vals({inspectionDASAlarmsYes:''}, false);
            form.errs({inspectionDASAlarmsYes:null});
        }
    })
    .defineAction('save',saveFormAction,changeAndSaveValidators)
    .defineAction('submit',submitFormAction,{
        meetAS4747:[mandatoryValidator("This is required")],
        detailsNoAS4747 : [preConditionToValidate((val, vals)=>{return vals.meetAS4747===false},txtMandatoryValidator("This is required"))],
        elecIntegrityChecks:[mandatoryValidator("This is required")],
        detailsNoElecIntegrityChecks : [preConditionToValidate((val, vals)=>{return vals.elecIntegrityChecks===false},txtMandatoryValidator("This is required"))],
        powerSupplyInSpec:[mandatoryValidator("This is required")],
        detailsNoPowerSupplyInSpec : [preConditionToValidate((val, vals)=>{return vals.powerSupplyInSpec===false},txtMandatoryValidator("This is required"))],
        solarChargingInSpec:[mandatoryValidator("This is required")],
        detailsSolarChargingInSpec : [preConditionToValidate((val, vals)=>{return vals.solarChargingInSpec===false},txtMandatoryValidator("This is required"))],
        inspectionDASAlarms:[mandatoryValidator("This is required")],
        inspectionDASAlarmsYes : [preConditionToValidate((val, vals)=>{return vals.inspectionDASAlarms===true},txtMandatoryValidator("This is required"))],
        upstreamPipeLength:[txtMandatoryValidator("This is required"),positiveNumberValidator('Please enter a valid number')],
        downstreamPipeLength:[txtMandatoryValidator("This is required"),positiveNumberValidator('Please enter a valid number')],
        lidSerialNumber:[txtMandatoryValidator("This is required")],
        lidConfigurationUnit:[mandatoryValidator("This is required")],
        scallingFactor:[mandatoryValidator("This is required")],
        inspectionCompletedDate:[mandatoryValidator("This is required")],
        inspectionMeterRead:[txtMandatoryValidator("This is required"),positiveNumberValidator('Please enter a valid number')],
        inspectionDASRawFlow:[txtMandatoryValidator("This is required"),positiveNumberValidator('Please enter a valid number')],
        instructions:[txtMandatoryValidator("This is required")],

        tamperPhotoDoc:[mandatoryValidator("This is required")],
        meterEmplacementDoc:[mandatoryValidator("This is required")],
        meterReadingDoc:[mandatoryValidator("This is required")],
        originalTamperSealNumbers:[(val)=>{
            if(val.length==0) return "Please provide at least one tamper seal number";
            return null;
        }],
        newTamperSealNumbers:[(val)=>{
            if(val.length==0) return "Please provide at least one tamper seal number";
            return null;
        }]
    })
    ;
    return result;
}