import React from 'react';
import axios from 'axios';
import moment from 'moment';
import * as bootstrap from 'bootstrap';
import Authentication from '../../helpers/Authentication'
import uiHelpers from '../../helpers/uiHelpers'
import {convertRegistrationPAManagerAction} from '../../helpers/converters';
import {ReactSelect,InputText} from '../../components/tools/BootstrapControls'
import { Refresh} from '../../components/tools/Icons'

const authentication = new Authentication();

class Admin extends React.Component {

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
     };

    constructor(props) {
        super(props);
        this.state = {
            audits : [],
            loading : false,

            adminComment : null,
            adminCommentError : null,
            adminAction : null,
            lid : null
        }
    }

    #dasResultModal;
    #confimationModal;
    async componentDidMount() {
        this.#dasResultModal = new bootstrap.Modal(document.getElementById('dasResultModal'));
        this.#confimationModal = new bootstrap.Modal(document.getElementById('confimationModal'));
        this.refreshPowerautomateRecords();
    } 
    
    refreshPowerautomateRecords = async()=>{
        uiHelpers.showProgressOnTop("Loading power automate records...");
        this.setState({loading:true, dasError : null})
        try {
            const response = await axios.post('/meterregistration/power-automate', {id : this.props.id}, this.ajaxConfig);
            this.setState({
                audits : response.data.data
            })
            uiHelpers.hideProgressOnTop();


        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured when loading power automate records...');
        }
        this.setState({loading:false})
    }
    
    onChangeOfAction = (option)=>{
        this.setState({adminAction : option, adminCommentError : null, adminComment: null});
    }

    onChangeOfLID = (option)=>{
        this.setState({lid : option.value});
    }

    actionDASCheckValid = async ()=>{
        uiHelpers.showProgressOnTop("Loading...");
        this.setState({loading:true, dasError : null})
        try {
            const response = await axios.post('/meterregistration/das-check-site-valid', {id : this.props.id}, this.ajaxConfig);
            this.setState({dasReturn :  JSON.stringify(response.data.dasReturn)})
            
            this.#dasResultModal.show();
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured...');
        }
        this.setState({loading:false})
    }

    actionDASActivateSite = async () =>{
        uiHelpers.showProgressOnTop("Loading...");
        this.setState({loading:true, dasError : null})
        try {
            const response = await axios.post('/meterregistration/das-activate-site', {id : this.props.id}, this.ajaxConfig);
            this.setState({dasReturn :  JSON.stringify(response.data.dasReturn)})
            
            this.#dasResultModal.show();
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured...');
        }
        this.setState({loading:false})
    }

    actionRemoveSite = async() =>{
        uiHelpers.showProgressOnTop("Removing record...");
        this.setState({loading:true, dasError : null})
        try {
            const response = await axios.post('/meterregistration/remove-registration', {id : this.props.id, lid : this.state.lid, comments : this.state.adminComment}, this.ajaxConfig);
            if(response.data.success) {

            } else {
                this.setState({dasReturn :  JSON.stringify(response.data.dasReturn), dasError : 'Error occourred when trying to call the DAS API for updating LID, this record is not removed'})
                this.#dasResultModal.show();
            }
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured when removing record...');
        }
        this.setState({loading:false})
    }


    actionTriggerSetToInstall = ()=>this.triggerPowerautomate("SET_TO_INSTALL")
    actionTriggerSiteCreation = ()=>this.triggerPowerautomate("SUBMIT")
    actionTriggerUpdateRatingTable = ()=>this.triggerPowerautomate("UPDATE_RATING_TABLE")
    actionTriggerUpdateMAHDLevel = ()=>this.triggerPowerautomate("UPDATE_MAHD_LEVEL")

    triggerPowerautomate = async (action) => {
        uiHelpers.showProgressOnTop("Adding trigger...");
        this.setState({loading:true, dasError : null})
        try {
            const response = await axios.post('/meterregistration/trigger-powermate-action', {
                id : this.props.id,
                action,
                comments : this.state.adminComment
            }, this.ajaxConfig);
            this.setState({
                audits : response.data.data
            })
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured...');
        }
        this.setState({loading:false})
    }

    openConfirmModal = () => {
        this.#confimationModal.show();
    }

    confirm = () =>{
        this.#confimationModal.hide();
        if(this.state.adminAction.actionFunc) this.state.adminAction.actionFunc();
    }

    submit = ()=>{
        if(!this.state.adminAction) return;
        var hasError = false;
        if(this.state.adminAction.needComments) {
            if(!this.state.adminComment || this.state.adminComment.trim()==""){
                this.setState({adminCommentError : "Required"});
                hasError = true;
            } else {
                this.setState({adminCommentError : null});
            }
            
        }

        if(this.state.adminAction.needLID) {
            if(!this.state.lid) {
                this.setState({lidError : "Required"});
            } else {
                this.setState({lidError : null});
            }
        }

        if(hasError) return;

        if(this.state.adminAction.needConfirm) {
            this.openConfirmModal();
            return;
        }
        if(this.state.adminAction.actionFunc) this.state.adminAction.actionFunc();
    }

    adminCommentChanged = (e) =>{
        this.setState({adminComment: e.target.value})
    }

    isLIDForMultipleMeters = () =>{
        let lids = this.props.lidOptions.filter(l=>l.value == this.props.meterRegistration.lid);
        if(lids.length==0) {
           return false;
        } else if(lids[0].name == 'flowpro_campbell'){
           return true;
        }
        return false;
     }

    actionOptionsForAll = [
        { label: 'Remove this registration', value: 'REMOVE' , disabled: (this.props.meterRegistration.formStatus!=1 || this.isLIDForMultipleMeters()), needComments : true, needLID : true, actionFunc : this.actionRemoveSite, needConfirm:true},
        { label: 'Trigger site creation in DAS', value: 'SITE_CREATION' , disabled: this.props.meterRegistration.formStatus!=1, needComments : true, actionFunc : this.actionTriggerSiteCreation, needConfirm:true},
        { label: 'Triger set to install in DAS', value: 'SET_TO_INSTALL' , disabled: this.props.meterRegistration.formStatus!=2, needComments : true, actionFunc: this.actionTriggerSetToInstall, needConfirm:true}
    ]

    actionOptionsForStorageMeasure = [
        { label: 'Trigger rating table update to DAS', value: 'UPDATE_RATING_TABLE' , disabled: !(this.props.meterRegistration.formStatus==1 || this.props.meterRegistration.formStatus==2), needComments : true, actionFunc: this.actionTriggerUpdateRatingTable, needConfirm: true},
        { label: 'Trigger mAHD level update to DAS', value: 'UPDATE_MAHD' , disabled: this.props.meterRegistration.formStatus!=2, needComments : true, actionFunc: this.actionTriggerUpdateMAHDLevel, needConfirm: true}
    ]

    actionOptionsForDASApi = [
        { label: 'DAS API - Check whether site valid for install', value: 'DAS_VALIDATE' , disabled: this.props.meterRegistration.formStatus!=1, actionFunc : this.actionDASCheckValid},
        { label: 'DAS API - Activate site (SIM Card)', value: 'ACTIVATE_SITE' , disabled: this.props.meterRegistration.formStatus!=1, uatOnlyWarning : true, actionFunc : this.actionDASActivateSite, needConfirm : true}
    ]

    render() {
        return  <>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                <strong>Admin Actions</strong>
                </div>
                <div class="card-body">
                    <div className='row'>
                        <div className='col-md-6'>

                            <ReactSelect
                                className="mb-3"
                                label={<>Action <span className='text-danger'>*</span></>}
                                onChange={this.onChangeOfAction}
                                options={
                                    (this.props.meterRegistration.formType!='NON_URBAN' && this.props.meterRegistration.storageMeasurementMethodId == 'STORAGE_MESUREMENT') 
                                    ?
                                    [
                                        ...this.actionOptionsForAll
                                        ,
                                        ...this.actionOptionsForStorageMeasure
                                        ,
                                        ...this.actionOptionsForDASApi
                                    ]
                                    :
                                    [
                                        ...this.actionOptionsForAll
                                        ,
                                        ...this.actionOptionsForDASApi
                                    ]
                                }
                                isOptionDisabled={(option) => option.disabled}
                                value={this.state.adminAction?this.state.adminAction.value:null} 
                                disabled={this.state.loading}
                            />

                            {this.state.adminAction && this.state.adminAction.needComments && <InputText
                                label="Comments"
                                className="mb-3"
                                value={this.state.adminComment}
                                disabled={this.state.loading}
                                error={this.state.adminCommentError}
                                onChange={this.adminCommentChanged}
                            />}

                            {this.state.adminAction &&  this.state.adminAction.needLID &&<ReactSelect
                                label={<>LID device <span className='text-danger'>*</span></>}
                                onChange={this.onChangeOfLID}
                                className="mb-3"
                                value={parseInt(this.state.lid)} 
                                options={this.props.lidOptions}
                                disabled={this.state.loading}
                                error= {this.state.lidError}
                            />}

                            {this.state.adminAction &&  this.state.adminAction.uatOnlyWarning && <p className='alert alert-warning'>This function is for test only, and it will only call the DAS UAT environment even in production</p>}

                            <div className='text-end'>
                                <button className='btn btn-primary' disabled={this.state.loading} onClick={this.submit}>Submit</button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <hr/>

            <div class="card  mb-3" >
                <div className='card-body'>
                    <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.loading} onClick={this.refreshPowerautomateRecords}><Refresh/></button>
                </div>
            </div>

            <div class="card mb-5">
                <div class="card-header bg-primary text-white">
                <strong>Power Automate Triggers History</strong>
                </div>
                <div class="card-body">
                <table className='table table-striped fs-90'>
                    <thead>
                        <tr>
                            <th style={{width:"200px"}}>Trigger Date</th>
                            <th style={{width:"100px"}}>Manual</th>
                            <th style={{width:"150px"}}>Action</th>
                            <th style={{width:"200px"}}>Completed Date</th>
                            <th style={{width:"200px"}}>User</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(!this.state.audits || this.state.audits.length==0) && <tr className='table-primary'>
                            <td className='text-center' colSpan={10}>No records available</td>
                        </tr>}
                        {this.state.audits.map((record)=><>
                            <tr>
                                <td>
                                {moment((new Date(record.actionDate))).format('DD/MM/YYYY h:mma')}
                                </td>
                                <td>
                                {record.isManualTriggered ?"Yes" : "No" }
                                </td>
                                <td>
                                {convertRegistrationPAManagerAction(record.action)}
                                </td>
                                <td>
                                {record.completedDate && moment((new Date(record.completedDate))).format('DD/MM/YYYY h:mma') }
                                {!record.completedDate && ((new Date()-(new Date(record.actionDate))) > (600000)) && <em className='text-danger'>Might have issue</em>}
                                </td>
                                <td>
                                {record.actionUser.firstName} {record.actionUser.lastName}
                                </td>
                                <td>
                                {(record.comments)} 
                                </td>
                            </tr>
                        </>)}
                    </tbody>
                </table>
                </div>
            </div>

            <div class="modal" tabindex="-1" id="dasResultModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">DAS result</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        {this.state.dasError && <div className='alert alert-danger mb-3'>
                            {this.state.dasError}
                        </div>}
                        <textarea className='form-control' rows={10} onChange={(e)=>{e.preventDefault()}} value={this.state.dasReturn}/>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Got it</button>
                    </div>
                    </div>
                </div>
            </div>


            <div class="modal" tabindex="-1" id="confimationModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    
                        <div class="modal-body text-center">
                            <p className='my-4'>Are you sure that you want to continue your action?</p>
                            <p className='mb-4'>
                                <button className='btn btn-secondary' data-bs-dismiss="modal">Cancel</button> 
                                &nbsp;&nbsp;&nbsp;
                                <button className='btn btn-primary' onClick={this.confirm}>Confirm</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </>    
    }

}

export default Admin;
