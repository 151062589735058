import React from 'react';
import * as apiPathes from '../../apiPathes';
import * as serviceCaller from '../../serviceCaller'
import * as bootstrap from 'bootstrap';

import {convertDateIsoToAusFormat, convertOtherInFlowType, convertDateTimeIsoToAusFormat} from '../../conveters'
import {UneditableTextInput, InputText, Popover} from '../../../components/tools/BootstrapControls'
import {QuestionFill, Pen, Meter, Info} from '../../../components/tools/Icons'
import {isStringNullOrEmpty, isNumber3DecimalPositiveOnly} from '../../../helpers/validators'

const createFormFromStorage = (storage) => {
    
    const result = {
        isManual: storage.isManual,
        dasMeterExists: storage.dasMeterExists,
        secondaryMeterId: storage.secondaryMeterId,
        heightPriorToPeriodManual: storage.heightPriorToPeriodManual,
        heightPriorToPeriodManualAhd: storage.heightPriorToPeriodManualAhd,
        heightPriorToPeriodManualError:null,
        volumePriorToPeriodManual: storage.volumePriorToPeriodManual,
        heightPriorToPeriodDAS: storage.heightPriorToPeriodDAS,
        dasPriorDate: new Date(storage.dasPriorDate),
        volumePriorToPeriodDAS: storage.volumePriorToPeriodDAS,
        totalCompleteStorage: storage.totalCompleteStorage,
        nominatedMeasurementPeriodStorageDailys: storage.nominatedMeasurementPeriodStorageDailys.map(d=>{
            return {
                ...d,
                heightManualError: null,
                dirty:false
            }
        }),
        priorDirty: false, 
        dirty: false,
    }
    

    return result;
}

class StorageReadingForm  extends React.Component {

    storage;
    constructor(props){
        super(props);
        this.state = createFormFromStorage(props.storage);
        this.storage = props.storage;
    }
    
    #manualToolTip;
    componentDidMount() {
        var manualToolTip = document.getElementById('manualToolTip')
        if(manualToolTip) this.#manualToolTip = new bootstrap.Tooltip(manualToolTip)
    }

    componentWillUnmount() {
        if(this.#manualToolTip) this.#manualToolTip.dispose();
    }

    toggleIsManual = async () => {
        await serviceCaller.post(apiPathes.saveStorageIsManual,
            {
                nominatedMeasurementPeriodStorageId: this.storage.id,
                isManual: this.state.isManual ? false:true
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Turning on/off manul entry of readings....',
                beforeSend: ()=>{
                    this.props.setDisableFunctions(true)
                },
                success: (data)=>{
                    this.props.updatePeriod(data,false,false,);
                }
            }
        )
    }

    validateReadings =() =>{
        var hasError = false;
        this.state.heightPriorToPeriodManualError = null;

        if(this.state.heightPriorToPeriodManual && typeof this.state.heightPriorToPeriodManual === 'string'  && !isNumber3DecimalPositiveOnly(this.state.heightPriorToPeriodManual)) {
            this.state.heightPriorToPeriodManualError ="This must be a positive number with maximum 3 decimal places";
        } else if(this.state.heightPriorToPeriodManual && typeof this.state.heightPriorToPeriodManual === 'string') {
            let height = parseFloat(this.state.heightPriorToPeriodManual) + this.storage.conversionFactor;
            
            if(height>this.props.storage.maxStorageVolume || height<this.props.storage.minStorageVolume) {
                this.state.heightPriorToPeriodManualError ="This is outside the range of the storage curve";
            }
            
        }

        var height;
        this.state.nominatedMeasurementPeriodStorageDailys.forEach(d=>{
            d.heightManualError = null;
            if(d.heightManual && typeof d.heightManual === 'string'  && !isNumber3DecimalPositiveOnly(d.heightManual)) {
                d.heightManualError ="This must be a positive number with maximum 3 decimal places";
                hasError = true;
            } else if(d.heightManual && typeof d.heightManual === 'string'){
                height = parseFloat(d.heightManual) + this.storage.conversionFactor;
                
                if(height>this.props.storage.maxStorageVolume || height<this.props.storage.minStorageVolume) {
                    d.heightManualError ="This is outside the range of the storage curve";
                    hasError = true;
                }
                
            }
        })

        hasError=hasError || this.state.heightPriorToPeriodManualError

        
        if(hasError){
            this.setState(this.state);
        }

        

        return !hasError;
    }

    saveChanges = async () => {
        if(!this.validateReadings()) return;

        await serviceCaller.post(apiPathes.saveStorageReading,
            {
                nominatedMeasurementPeriodStorageId: this.storage.id,
                isManual: this.state.isManual,
                heightPriorToPeriodManual: isStringNullOrEmpty(this.state.heightPriorToPeriodManual) ? null : parseFloat(this.state.heightPriorToPeriodManual),
                nominatedMeasurementPeriodStorageDailys : this.state.nominatedMeasurementPeriodStorageDailys.map(d=>{return {
                    id:d.id,
                    heightManual:isStringNullOrEmpty(d.heightManual) ? null : parseFloat(d.heightManual)
                }})
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Saving your readings....',
                beforeSend: ()=>{
                    this.props.setDisableFunctions(true, true)
                    this.setState(this.state)
                },
                success: (data)=>{
                    this.props.updatePeriod(data,false,false,);
                }
            }
        )
    }

    useDasDataDaily = (dailyRecord) => {
        dailyRecord.heightManual = dailyRecord.heightDAS;
        dailyRecord.dirty=true;
        this.state.dirty=true;
        this.setState(this.state)
    }

    dailyHeightChange = (value,dailyRecord) => {

        var heightAHD;
        if(isNumber3DecimalPositiveOnly(value)) {
            heightAHD = parseFloat(value) + this.storage.conversionFactor
        } else {
            heightAHD = null;
        }

            
        dailyRecord.heightManual = value;
        dailyRecord.heightManualAhd = heightAHD;
        dailyRecord.dirty = true;
        this.state.dirty=true;
        this.setState(this.state)
    }

    useDasDataPiror = () => {

        this.setState({...this.state, heightPriorToPeriodManual:this.state.heightPriorToPeriodDAS,priorDirty:true,dirty:true});
    }

    priorHeightChange = (e) =>{
        var heightAHD;
        if(isNumber3DecimalPositiveOnly(e.target.value)) {
            heightAHD = parseFloat(e.target.value) + this.storage.conversionFactor
        } else {
            heightAHD = null;
        }

        this.setState({...this.state,priorDirty:true, dirty:true, heightPriorToPeriodManual: e.target.value, heightPriorToPeriodManualAhd: heightAHD});
    }

    reset = () => {
        this.setState( createFormFromStorage(this.props.storage))
    }

    isDailyRecordOnPeriodEnd =(record)=>{
        const period = this.props.nominatedMeasurementPeriod;
        if(!period.periodEndDate) return false;
        const periodEndDate = new Date(period.periodEndDate);
        const dailyDate = new Date(record.dailyDate);

        return periodEndDate.getFullYear() == dailyDate.getFullYear() && periodEndDate.getMonth() == dailyDate.getMonth() && periodEndDate.getDate() == dailyDate.getDate();
    }

    render(){

        if(this.storage != this.props.storage){
            this.storage = this.props.storage;
            this.setState( createFormFromStorage(this.props.storage))
        }
        //const form = this.state;
        const {disableFunctions} = this.props;
        const period = this.props.nominatedMeasurementPeriod;
        return <>
            
            {this.state.dasMeterExists && this.state.secondaryMeterId && <div class="form-check form-switch mb-3">
                <input class="form-check-input" type="checkbox" id="manualCheck" checked={this.state.isManual} disabled={disableFunctions || period.status == 'FINALISED'} onChange={this.toggleIsManual}/>
                <label class="form-check-label text-primary" for="manualCheck" >
                    Use secondary measurement
                </label>
                <span id="manualToolTip" className='ms-2' data-bs-toggle="tooltip" data-bs-placement="top" title="Use this when storage measurement device not available yet or storage measurement device is damaged"><QuestionFill/></span>
            </div>}

            {!this.state.secondaryMeterId && <div className='row mb-3'>
                <div className='col-md-4'>
                    <div className='alert alert-secondary'>
                        <Meter/> Telemetry reading is used for this storage, there is no secondary measurement device for this storage.
                    </div>
                </div>
            </div>}

            {!this.state.dasMeterExists && <div className='row mb-3'>
                <div className='col-md-4'>
                    <div className='alert alert-secondary'>
                        <Pen/> Secondary measurement is used for this storage, there is no telemetry device for this storage.
                    </div>
                </div>
            </div>}


            <div>
                {this.state.isManual && <div className='row mb-3'>
                    <div class="col-md-4">
                        {period.status != 'FINALISED'  
                        ? 
                        <>
                            <label class="form-label">Height (gauge reading in meter) prior to start period:</label>
                            <div class={`input-group`}>
                                <input type="text" class={`form-control ${this.state.heightPriorToPeriodManualError?'is-invalid':''}`}  value={this.state.heightPriorToPeriodManual?this.state.heightPriorToPeriodManual:""} 
                                    onChange={this.priorHeightChange} disabled={disableFunctions}
                                />
                                {/*this.state.dasMeterExists && <button class="btn btn-outline-secondary" type="button" disabled={disableFunctions} onClick={this.useDasDataPiror}><ArrowLeft/>DAS</button>*/}
                            </div>
                            {this.state.isManual &&  <small className='text-primary'><i>Between {(this.props.storage.minStorageVolume - this.props.storage.conversionFactor).toFixed(2)} and {(this.props.storage.maxStorageVolume - this.props.storage.conversionFactor).toFixed(2)}</i></small>}
                            {this.state.heightPriorToPeriodManualError && <div className='text-danger'>{this.state.heightPriorToPeriodManualError}</div>}
                        </>
                        :
                        <UneditableTextInput
                            fieldExtraClass="bg-light"
                            label="Height (gauge reading in meter) prior to start period:"
                            value={this.state.heightPriorToPeriodManual}
                        />
                        }
                    </div>
                </div>}

                <div className='row  mb-3'>
                    <div class="col-md-4 mb-3">
                        {this.state.isManual && <UneditableTextInput
                            fieldExtraClass="bg-light"
                            label="Height (AHD in meter) prior to start period:"
                            value={!this.state.heightPriorToPeriodManualAhd ? <small className='text-black-50'><em>Will be calculated</em></small> : <strong>{this.state.heightPriorToPeriodManualAhd}</strong>}
                        />}
                        {!this.state.isManual && <UneditableTextInput
                            label="Height (AHD in meter) prior to start period:"
                            value={<div>
                                <strong>{this.state.heightPriorToPeriodDAS}</strong> <br/>
                                <small className='text-muted'><em>at {convertDateTimeIsoToAusFormat(this.state.dasPriorDate)} from DAS</em></small>
                            </div>}
                        />}
                    </div>
                </div>

                <div className='row  mb-3'>
                    <div class="col-md-4 mb-3">
                        {this.state.isManual && <UneditableTextInput
                            fieldExtraClass="bg-light"
                            label="Volume (ML) prior to start period:"
                            value={this.state.priorDirty || !this.state.volumePriorToPeriodManual ? <small className='text-black-50'><em>Will be calculated</em></small> : <strong>{this.state.volumePriorToPeriodManual}</strong>}
                        />}
                        {!this.state.isManual && <UneditableTextInput
                            label="Volume (ML) prior to start period:"
                            value={<div>
                                <strong>{this.state.volumePriorToPeriodDAS}</strong> <br/>
                                <small className='text-muted'><em>at {convertDateTimeIsoToAusFormat(this.state.dasPriorDate)} from DAS</em></small>
                            </div>}
                        />}
                    </div>
                </div>
                
                <h4>Daily logs  </h4>
                <table className='table mb-3'>
                    <thead>
                        <tr className='table-primary'>
                            <th style={{"width":this.state.isManual?"250px":"300px"}}>
                                Date
                            </th>
                            {this.state.isManual && <th style={{"width":this.state.isManual?"250px":"200px"}}>
                                Height (gauge reading)
                            </th>}
                            <th style={{"width":this.state.isManual?"250px":"200px"}}>
                                Height (AHD)
                            </th>
                            {/*<th style={{"width":"200px"}}>
                                Area
                            </th>*/}
                            <th>
                                Volume
                            </th>
                        </tr>
                        <tr >
                            <td></td>
                            {this.state.isManual && <th><small>m</small></th>}
                            <th><small>m</small></th>
                            {/*<th><small>Ha</small></th>*/}
                            <th><small>ML</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.nominatedMeasurementPeriodStorageDailys.map(d=>{
                            if(this.state.isManual && period.status == 'FINALISED') return <tr>
                                <td>{convertDateIsoToAusFormat(d.dailyDate)}</td>
                                {this.state.isManual && <td>{d.heightManual}</td>}
                                <td>{d.heightManualAhd}</td>
                                {/*<td>{d.areaManual}</td>*/}
                                <td>{d.volumeManual}</td>
                            </tr>;

                            if(this.state.isManual) return <tr>
                                <td>
                                    {convertDateIsoToAusFormat(d.dailyDate)}
                                    {this.isDailyRecordOnPeriodEnd(d) && <><span className='text-danger'>*</span><br/><small className='text-primary'><em>This is mandatory on finalising</em></small></>}
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input type="text" class={`form-control ${d.heightManualError?'is-invalid':''}`}  value={d.heightManual?d.heightManual:""} 
                                            onChange={(e)=>this.dailyHeightChange(e.target.value,d)}
                                            disabled={disableFunctions}
                                        />
                                        {/*this.state.dasMeterExists && <button class="btn btn-outline-secondary" type="button" disabled={disableFunctions}  onClick={()=>this.useDasDataDaily(d)}><ArrowLeft/>DAS</button>*/}
                                    </div>
                                    {this.state.isManual &&  <small className='text-primary'><i>Between {(this.props.storage.minStorageVolume - this.props.storage.conversionFactor).toFixed(2)} and {(this.props.storage.maxStorageVolume - this.props.storage.conversionFactor).toFixed(2)}</i></small>}
                                    {d.heightManualError && <div className='text-danger'>{d.heightManualError}</div>}
                                </td>
                                <td>{!d.heightManualAhd ? <small className='text-muted fst-italic'>Will be calculated</small> : d.heightManualAhd}</td>
                                {/*<td>{d.areaManual&&d.dirty? <small className='text-muted fst-italic'>Will be calculated</small> : d.areaManual}</td>*/}
                                <td>{d.volumeManual&&d.dirty? <small className='text-muted fst-italic'>Will be calculated</small> : d.volumeManual}</td>
                            </tr>
                        
                            return <tr>
                                <td>
                                    {convertDateIsoToAusFormat(d.dailyDate)}
                                    {d.dasDate && <><br/><small className='text-muted'><em>(data at {convertDateTimeIsoToAusFormat(d.dasDate)} from DAS)</em></small></>}
                                </td>
                                <td>{d.heightDAS!=null ? d.heightDAS : <em className='text-muted'>No data</em>}</td>
                                {/*<td>{d.areaDAS!=null ? d.areaDAS : <em className='text-muted'>No data</em>}</td>*/}
                                <td>{d.volumeDAS!=null ? d.volumeDAS : <em className='text-muted'>No data</em>}</td>
                            </tr>
                        })}
                        <tr className='table-secondary'>
                            <td colSpan={this.state.isManual ? 3 : 2} className="text-end"><strong className='text-primary'>Change in storage volume:</strong></td>
                            <td>
                                {this.state.dirty ? <em className='text-muted'>Will be recalculated on save</em> : 
                                this.state.totalCompleteStorage? <strong className='text-primary'>{this.storage.totalVolumeChange} {this.storage.totalVolumeChange!=null && "ML"}</strong> : 
                                <small><em className='text-danger'>Data incomplete to calculate</em></small>}
                            </td>
                        </tr>
                    </tbody>
                </table>

                {period.status != 'FINALISED' && this.state.isManual && <div className='text-end'>
                    {this.state.dirty && <div className='text-warning'>{disableFunctions&&<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} You have changes that not saved </div>}
                    {!this.state.dirty && <div className='text-muted'>No changes to save yet..</div>}
                    <button className='btn btn-primary me-3'  disabled={disableFunctions || !this.state.dirty} onClick={this.saveChanges }>Save changes</button>
                    <button className='btn btn-outline-secondary' disabled={disableFunctions|| !this.state.dirty} onClick={this.reset }>Reset</button>
                </div>}
            </div>
        </>
    }

}

export default StorageReadingForm;